.accountmanager-activitylist {
    margin-top: rem-calc(12);

    @include breakpoint(medium) {
        margin-top: rem-calc(20);
    }

    &__title {
        width: auto;
        display: inline-block;

        &-badge {
            background-color: map-deep-get($colors, "orange", "default");
            color: map-deep-get($colors, "white", "default");
            border-radius: 50%;
            display: inline-block;
            font-size: 70%;
            line-height: 1rem;
            padding: rem-calc(3);
            float: right;
            margin-left: rem-calc(3);
            min-width: rem-calc(22);
            min-height: rem-calc(22);
            text-align: center;
        }
    }

    &__list {
        &-item {
            a {
                color: map-deep-get($colors, "black", "default");
                text-decoration: underline;
            }
        }
    }
}
