.add-own-item-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    margin-left: rem-calc(9999);
    opacity: 0;
    transition: opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear .4s;
    $add-pointer-popup: &;

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
    }

    &__content {
        position: relative;
        z-index: 2;
        max-width: rem-calc(1024);
        width: 100%;
        transition: all .4s cubic-bezier(.2, 0, 0, 1);
        transform: translateY(30px);
        background-color: map-deep-get($colors, "white", "default");
        padding: 32px;
        border-radius: $border-radius-default;
        opacity: 0;

        &--active {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__title {
        margin-bottom: rem-calc(32);
    }

    &__label {
        border: 3px dashed map-deep-get($colors, "gray", "default");
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: rem-calc(40);
        border-radius: $border-radius-default;
        font-size: rem-calc(40);
        color: map-deep-get($colors, "gray", "default");
        cursor: pointer;
        height: rem-calc(300);
        overflow-y: auto;
    }

    &__input {
        position: absolute;
        left: rem-calc(-9999);
    }

    &__controls {
        margin-top: rem-calc(24);
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__add-item-button {
        margin-left: rem-calc(12);
    }

    &--active {
        opacity: 1;
        margin-left: 0;
        transform: translateY(0);
        transition: transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear;
    }

    &__uploads {
        display: flex;
        gap: 1rem;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 0;
    }

    &__upload_item {
        background: #efefef;
        padding: 0.5rem;
        flex: 1 1 calc(20% - 1rem);

        &--image-wrapper {
            display: flex;
            aspect-ratio: 16/9;
            object-fit: cover;
            justify-content: center;
        }

        &--image {
            max-width: 100%;
            max-height: 100%;
        }

        &--label {
            font-size: 1rem;
            line-height: 1rem;
            display: block;
            padding: 0.5rem;
            text-align: center;
            color: #000;
        }
    }
}
