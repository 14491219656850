.step-numbers {
    display: flex;
    position: relative;
    overflow: hidden;
    $step-numbers: &;

    &__num-list {
        position: relative;
        display: inline-flex;
        flex-direction: column-reverse;
    }

    &__num-list-content {
        position: absolute;
        top: 0;
        bottom: 0;
        overflow: hidden;
        transition: all .6s cubic-bezier(.2, 0, 0, 1);
    }

    &__num {
        display: block;
        position: relative;
    }

    &--slide-1 {
        #{$step-numbers}__num-list-content {
            top: -100%;
        }
    }

    &--slide-2 {
        #{$step-numbers}__num-list-content {
            top: -200%;
        }
    }
}