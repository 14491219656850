.form-controls {
    margin-top: rem-calc(32);

    &::after {
        content: "";
        display: block;
        clear: both;
    }

    &__button {
        &--right {
            float: right;
        }
    }
}
