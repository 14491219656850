.quotation-buttons {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-top: rem-calc(16);

    @include breakpoint(medium) {
        justify-content: flex-end;
        margin-top: 0;
    }

    &__button {
        margin: rem-calc(16) rem-calc(12) 0 0;

        &:last-of-type {
            margin-left: 0;
        }
    }
}