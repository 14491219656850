.product-summary {
    margin-top: rem-calc(24);

    &__image-wrapper {
        position: relative;
        text-align: center;
    }

    &__image-container {
        position: relative;
        display: inline-block;
    }

    &__pointers {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__image {
        max-height: rem-calc(600);
        max-width: 100%;
    }

    &__price-section {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: rem-calc(8);
        padding-bottom: 8px;
        border-bottom: 1px solid map-deep-get($colors, "gray", "dark");

        @include breakpoint(medium) {
            margin-top: rem-calc(32);
        }
        @include breakpoint(large) {
            margin-top: rem-calc(64);
        }
    }

    &__price {
        margin: rem-calc(8) 0;
    }

    &__per {
        font-size: 15px;
        padding-left: 6px;
    }

    &__save {
        margin: rem-calc(8) 0 rem-calc(8) rem-calc(8);
    }

    &__purchase {
        margin-top: rem-calc(16);
    }

    &__text {
        margin-top: rem-calc(40);
        margin-bottom: rem-calc(40);
    }

    &__caption {
        margin-top: rem-calc(32);
        display: block;
    }

    &__total-price {
        padding: 15px;
    }

    &__variants {
        margin-top: rem-calc(8);

        @include breakpoint(medium) {
            margin-top: rem-calc(16);
        }
        @include breakpoint(large) {
            margin-top: rem-calc(32);
        }
    }
}
