.product-config-search {
    width: 100%;
    max-width: rem-calc(560);
    margin: 0 auto rem-calc(20);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__content {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }

    &__input-wrapper {
        display: flex;
        position: relative;
        width: 100%;
    }

    &__icon {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px 0 24px;
        cursor: pointer;
        z-index: 1;

        svg {
            width: rem-calc(18);
            height: auto;
        }
    }

    &__button {
        margin-top: rem-calc(20);
        padding: 0 40px;
        width: 100%;
        position: relative;
        z-index: 1;

        @include breakpoint(medium) {
            margin: 0 0 0 rem-calc(-50);
            width: auto;
        }
    }

    &__input {
        text-indent: rem-calc(40);
        border-radius: rem-calc(20);
    }

    &__advanced {
        margin-top: rem-calc(20);
        color: map-deep-get($colors, "green", "default");
        text-decoration: underline;
    }
}