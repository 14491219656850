.order-deliveryform {

    $order-deliveryform: &;

    /* stylelint-disable */
    .form__group {
        margin-bottom: rem-calc(24);

        .form__input[readonly], .form__input--textarea[readonly], .input[readonly] {
            background-color: #efefef;
            cursor: not-allowed;
        }
    }
    /* stylelint-enable */

    &__page {
        margin-bottom: rem-calc(40);
        padding: rem-calc(30);
        border: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__section {
        width: 100%;
    }

    &__section:not(:first-of-type) {
        margin-top: rem-calc(40);
        padding-top: rem-calc(30);
        border-top: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__heading {
        margin-bottom: rem-calc(10);
    }

    &__disclaimer {
        color: map-deep-get($colors, "validation", "invalid");
    }

    &__checkbox-inline {
        /* stylelint-disable */
        .form__valueWrapper {
            display: inline-block;
            margin-right: rem-calc(10);
        }
        /* stylelint-enable */
    }

    &--ignore-group-margin {
        /* stylelint-disable */
        .form__group {
            margin-bottom: 0;
        }
        /* stylelint-enable */

        #{$order-deliveryform}__disclaimer {
            margin-bottom: 0;
        }
    }

    &__other-input {
        /* stylelint-disable */
        label {
            font-weight: 400;
        }
        /* stylelint-enable */
    }
}

.order-deliveryform--print {
    .print-button {
        float: right;
        width: auto;
    }
}

@media print {
    .order-deliveryform--print {
        /* stylelint-disable */
        .print-button {
            display: none;
        }

        .main.main--push-top {
            padding-top: 20px !important;
        }

        .grid-x {
            display: block;
            overflow: hidden;

            .cell {
                display: block;
                float: none;
                clear: both;
            }

            .medium-12 {
                clear: none;
                float: left;
                width: calc(50% - 1.25rem);
            }
        }

        .order-deliveryform__page {
            border: 0;
            padding-left: 0.25rem;
            padding-right: 0.25rem;
        }

        .order-deliveryform__back-button {
            display: none;
        }

        .icon-title--push-top {
            margin-top: 0 !important;
        }

        .form__group .textarea,
        .form__group .textarea[readonly],
        .form__group .input,
        .form__group .input[readonly] {
            background-color: transparent;
            border-width: 0 0 1px 1px;
            padding-left: 0.5rem;
        }

        .form__group .textarea,
        .form__group .textarea[readonly] {
            height: fit-content;
            min-height: 50px;
            resize: none;
        }

        .cell.large-18, .cell.xlarge-16 {
            width: calc(100% - 1.25rem);
        }

        .order-deliveryform__section .cell {
            page-break-inside: avoid;
            break-inside: avoid;
        }

        .order-deliveryform--page-break {
            page-break-before: auto;
            page-break-inside: avoid;
            break-before: auto;
            break-inside: avoid;
        }

        a::after {
            display: none;
        }

        footer {
            display: none;
        }

        .radio-wrapper input:checked+.radio-wrapper__input-dot::before,
        .radio-wrapper__input:checked+.radio-wrapper__input-dot::before {
            content: "";
            border-color:#77c19a;
            width: 0;
            height: 0;
            border-radius: 50%;
            border-width: 8px;
            border-style: solid;
            display: block;
        }
        /* stylelint-enable */
    }
}
