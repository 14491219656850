@keyframes pulse-pointer {
    0% {
        transform: scale(.9);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(.9);
    }
}

@keyframes pulse-ring {
    0% {
        transform: scale(.33);
    }

    80%, 100% {
        opacity: 0;
    }
}

.pointer {
    width: rem-calc(24);
    height: rem-calc(24);
    margin-left: rem-calc(-18);
    margin-top: rem-calc(-18);
    position: relative;
    z-index: 2;

    svg {
        .st0 {
            fill: map-deep-get($colors, "white", "default");
        }

        .st1 {
            fill: map-deep-get($colors, "blue", "default");
        }
    }

    &__out-of-stock {
        svg {
            .st1 {
                fill: map-deep-get($colors, "out_of_stock", "default");
            }
        }
    }

    svg ellipse {
        animation: pulse-pointer 1.25s cubic-bezier(.45, .03, .51, .95) -.4s infinite;
        transform-origin: center;
        position: relative;
    }

    &__icon {
        position: relative;
        z-index: 2;
        cursor: pointer;

        svg {
            width: rem-calc(24);
            height: rem-calc(24);
            border: rem-calc(2) solid map-deep-get($colors, "white", "default");
            border-radius: 50%;
            box-shadow: 0 0 rem-calc(10) map-deep-get($colors, "black", "default");
            background-color: map-deep-get($colors, "white", "default");
        }
    }

    &::before, &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        display: block;
        width: 200%;
        height: 200%;
        margin-left: -50%;
        margin-top: -50%;
        border-radius: 50%;
        border: 1px solid map-deep-get($colors, "green", "light");
    }

    &::before {
        animation: pulse-ring 1.25s cubic-bezier(.21, 0.61, .35, 1) infinite;
    }

    &::after {
        animation: pulse-ring 1.25s cubic-bezier(.21, 0.61, .35, 1) .2s infinite;
    }
}
