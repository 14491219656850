.knowledge-detail-info {
    border: 1px solid map-deep-get($colors, "gray", "default");
    border-radius: rem-calc(10);
    padding: 24px;
    margin-bottom: rem-calc(32);

    &__label {
        font-weight: 700;
    }

    &__list {
        &--second {
            padding-top: 16px;
            margin-top: rem-calc(16);
            border-top: 1px solid map-deep-get($colors, "gray", "default");
        }
    }

    &__list-item {
        &--categories {
            margin-top: rem-calc(18);
        }
    }

    &__category {
        background-color: map-deep-get($colors, "green", "lightest");
        color: map-deep-get($colors, "green", "default");
        padding: 4px 8px;
        display: inline-block;
        margin: 0 0 rem-calc(8) rem-calc(8);
    }
}