.icon-title {
    position: relative;
    margin-bottom: rem-calc(32);

    @include breakpoint(medium) {
        margin-bottom: 0;
    }

    &__title {
        margin-bottom: 20px;
    }

    &__caption {
        display: flex;
        align-items: center;
        color: map-deep-get($colors, "blue", "default");
    }

    &__intro {
        margin: 0 0 5px;
    }

    &__text {
        margin: 0 0 5px;
    }

    &__icon {
        margin-right: rem-calc(8);
        height: rem-calc(20);
        width: auto;
    }

    &__button {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__edit {
        min-height: rem-calc(30);
        overflow: hidden;
        line-height: 1.6;
        resize: none;
    }

    &__discussion {
        color: map-deep-get($colors, "black", "default");
        text-decoration: underline;

        &:hover {
            color: map-deep-get($colors, "green", "default");
        }
    }

    &--push-top {
        margin-top: rem-calc(24);

        @include breakpoint(medium) {
            margin-top: rem-calc(64);
        }
    }
}
