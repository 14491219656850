.accountmanager-overview {
    $el: &;
    position: relative;
    min-height: rem-calc(500);

    &__plain-link {
        text-decoration: underline;
        color: map-deep-get($colors, "blue", "default");
        display: inline-block;

        svg {
            width: rem-calc(25);
            height: rem-calc(25);
            float: right;
            transition: $transition-default;
        }

        &:hover {
            svg {
                margin-left: rem-calc(5);
            }
        }
    }

    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(map-deep-get($colors, "white", "default"), 0.9);
        text-align: center;
        padding-top: rem-calc(202);
        font-weight: 700;
    }

    &__results {
        $el_results: &;
        position: relative;

        /* stylelint-disable */
        &-count {
            padding: rem-calc(10) 0;
            text-align: right;
        }

        &-loading {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(map-deep-get($colors, "white", "default"), 0.9);
            text-align: center;
            padding-top: rem-calc(114);
            font-weight: 700;
            z-index: 11;
        }

        &-item {
            background-color: map-deep-get($colors, "gray", "lighter");
            cursor: pointer;

            &:hover {
                background-color: map-deep-get($colors, "blue", "lightest");
            }

            #{$el_results}-icon {
                position: absolute;
                svg {
                    width: rem-calc(30);
                    height: rem-calc(30);
                }
                top: 50%;
                right: rem-calc(10);
                margin-top: rem-calc(-15);
            }
        }

        &-header {
            font-weight: 700;
        }

        &-item,
        &-header {
            margin-bottom: rem-calc(5);
            padding: rem-calc(10) rem-calc(50) rem-calc(10) rem-calc(10);
            position: relative;
        }
        /* stylelint-enable */
    }
}
