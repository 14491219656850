.shoppingcart {
    padding: 40px 20px;
    position: relative;
    $shoppingcart: &;

    @include breakpoint(medium) {
        padding: 80px 40px;
    }
    @include breakpoint(xlarge) {
        padding: 80px;
    }
    @include breakpoint(xxlarge) {
        padding: 80px 15%;
    }

    &--quotation {
        padding-left: 0;
        padding-right: 0;

        @include breakpoint(medium) {
            padding-left: 0;
            padding-right: 0;
        }
        @include breakpoint(xlarge) {
            padding-left: 0;
            padding-right: 0;
        }
        @include breakpoint(xxlarge) {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__info-wrapper {
        display: inline-block;
        position: relative;
        margin-left: rem-calc(20);
        padding-bottom: 10px;
    }

    &__info-icon {
        background-color: map-deep-get($colors, "green", "default");
        border-radius: 50%;
        color: map-deep-get($colors, "white", "default");
        width: rem-calc(22);
        height: rem-calc(22);
        line-height: rem-calc(22);
        text-align: center;
        font-weight: 700;
        margin-top: rem-calc(10);
        cursor: pointer;
    }

    &__info-content {
        background-color: map-deep-get($colors, "green", "lightest");
        border-radius: $border-radius-default;
        box-shadow: $box-shadow-default;
        padding: 20px;
        position: absolute;
        width: rem-calc(500);
        max-width: 55vw;
        line-height: 1.6;
        z-index: 9;
        font-weight: 400;
        display: none;
        opacity: 0;
        transform: translateY(25%);
        transition: $transition-default;

        &--first {
            display: block;
            opacity: 0;
        }

        &--second {
            opacity: 1;
            transform: translateY(0%);
        }

        &--top-left {
            right: 0;
            bottom: 100%;
        }

        &--top-right {
            left: 0;
            bottom: 100%;
        }

        &--bottom-left {
            right: 0;
        }

        &--bottom-right {
            left: 0;
        }
    }

    &__loading {
        position: absolute;
        left: 0;
        right: 0;
        top: 30%;
        text-align: center;
    }

    @media print {
        padding-top: 0;
    }
}
