.product-config-results {
    margin-top: rem-calc(40);

    &__title {
        margin-bottom: rem-calc(24);
    }

    &__loading {
        display: inline-flex;
        align-items: center;
        margin-bottom: rem-calc(40);

        svg {
            margin-right: rem-calc(10);
        }
    }

    &__items {
        transition: $transition-default;

        &--loading {
            opacity: .5;
        }
    }

    &__paginator {
        float: left;
    }

    &__per-page {
        width: 10rem;
        float: right;
    }
}
