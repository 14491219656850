.vh-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    height: auto;
    position: relative;
    padding: 60px 0;

    @include breakpoint(large) {
        padding: 180px 0 60px;
    }
    @include breakpoint(large) {
        min-height: 100vh;
    }

    &__content {
        width: 100%;
        padding: 0 30px;

        @include breakpoint(large) {
            padding: 0 20%;
        }
    }

    &--fixed {
        position: fixed;
    }
}