.pace {
    -webkit-pointer-events: none;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    opacity: 1;
    transition: $transition-default .2s;
    position: fixed;
    z-index: 15;
}

.pace-inactive {
    opacity: 0;
}

.pace .pace-progress {
    background-color: map-deep-get($colors, "green", "default");
    position: fixed;
    box-shadow: 0 2px 4px 0 rgba(map-deep-get($colors, "black", "default"), .3);
    z-index: 15;
    top: 0;
    right: 100%;
    width: 100%;
    height: 2px;
    transition: all .6s ease-in-out;
}
