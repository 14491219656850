.shoppingcart-details {
    background-color: map-deep-get($colors, "green", "lightest");
    padding: 24px;
    border-radius: $border-radius-default;
    margin: rem-calc(56) 0;

    &__address {
        color: rgba(map-deep-get($colors, "black", "default"), .5);
        font-weight: 300;
        margin-bottom: rem-calc(24);
    }

    &__title {
        font-weight: 700;
    }
}