.footer {
    padding: 48px 0 0;
    border-top: 2px solid map-deep-get($colors, "gray", "darker");
    position: relative;
    z-index: 2;
    background-color: map-deep-get($colors, "white", "default");

    &__logo {
        max-width: rem-calc(250);
        display: block;

        @include breakpoint(medium) {
            max-width: rem-calc(300);
        }

        svg {
            width: 100%;
        }
    }

    &__title {
        margin-top: rem-calc(40);
    }

    &__text {
        margin: rem-calc(8) 0 0 0;
    }

    &__buttons {
        margin-top: rem-calc(32);
        display: flex;
        flex-wrap: wrap;

        @include breakpoint(large) {
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    &__button {
        margin: rem-calc(32) rem-calc(32) 0 0;

        @include breakpoint(large) {
            margin-right: 0;
        }
    }

    &__icon-link {
        display: inline-flex;
        align-items: center;
        color: map-deep-get($colors, "black", "default");
        font-weight: 700;
        width: 100%;
        margin-top: rem-calc(32);

        @include breakpoint(large) {
            width: auto;
        }

        svg {
            margin-right: rem-calc(16);
            height: rem-calc(24);
            width: auto;
            fill: map-deep-get($colors, "blue", "default");
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__bottom {
        background-color: map-deep-get($colors, "gray", "light");
        margin-top: rem-calc(40);
        font-size: rem-calc(14);
    }

    &__bottom-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        min-height: rem-calc(80);
        padding: rem-calc(24) 0;
    }

    &__bottom-section {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include breakpoint(large) {
            width: auto;
        }
    }

    &__social {
        margin-top: rem-calc(32);
    }

    &__social-link {
        margin-right: rem-calc(16);
        display: inline-block;

        svg {
            transition: $transition-default;
        }

        &--linkedin {
            &:hover svg, &:focus svg {
                fill: map-deep-get($colors, "social", "linkedin");
            }
        }

        &--twitter {
            &:hover svg, &:focus svg {
                fill: map-deep-get($colors, "social", "twitter");
            }
        }

        &--facebook {
            &:hover svg, &:focus svg {
                fill: map-deep-get($colors, "social", "facebook");
            }
        }
    }

    &__copyright {
        margin-right: rem-calc(60);
        display: block;

        @include breakpoint(large) {
            display: inline-block;
        }
    }

    &__bottom-list {
        margin-top: rem-calc(16);
        width: 100%;

        @include breakpoint(large) {
            margin-top: 0;
            width: auto;
        }
    }

    &__bottom-list-item {
        display: block;
        margin: rem-calc(16) rem-calc(32) 0 0;

        @include breakpoint(medium) {
            display: inline-block;
            margin-top: 0;
        }

        @media print {
            display: none;
        }
    }

    &__bottom-webnl {
        display: inline-block;
        margin-top: rem-calc(32);

        @include breakpoint(medium) {
            margin-top: rem-calc(16);
        }
        @include breakpoint(large) {
            margin-top: 0;
        }

        @media print {
            display: none;
        }
    }

    &__bottom-link {
        color: map-deep-get($colors, "black", "default");

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    @media print {
        &__top {
            display: none;
        }
    }
}
