.steps {
    position: relative;
    margin-bottom: rem-calc(80);
    overflow: hidden;

    @include breakpoint(large) {
        margin-bottom: 0;
    }

    &__image {
        position: absolute;
        z-index: 1;
        left: 31%;

        @include breakpoint(medium) {
            left: 10%;
        }
        @include breakpoint(xlarge) {
            left: 20%;
        }
        @include breakpoint(xxxlarge) {
            left: 25%;
        }
    }
}
