.share-board-or-project {
    &__options {
        .form__group {
            margin: 0;
        }
    }

    &__strong {
        margin-bottom: rem-calc(10);
        display: inline-block;
    }

    &__title {
        margin-top: rem-calc(20);
    }

    &__mail {
        margin-top: rem-calc(10);
    }

    &__addresses {
        border: 1px solid map-deep-get($colors, "gray", "default");
        padding: 0 rem-calc(10) rem-calc(10);
        max-width: 450px;
        position: relative;
        margin-bottom: rem-calc(10);
    }

    &__address {
        background-color: map-deep-get($colors, "green", "lightest");
        border: 1px solid map-deep-get($colors, "green", "light");
        border-radius: 5px;
    }

    &__delete {
        cursor: pointer;

        svg {
            margin-left: rem-calc(4);
            height: rem-calc(20);
            width: auto;
            fill: map-deep-get($colors, "green", "light");
        }

        &:hover {
            svg {
                fill: map-deep-get($colors, "blue", "default");
            }
        }
    }

    &__input {
        appearance: none;
        border: 1px solid transparent;
        border-radius: 0;
        max-width: 100%;

        &, &:hover, &:focus {
            border: 1px solid transparent;
            height: auto;
        }
    }

    &__address,
    &__input {
        display: inline-flex;
        padding: rem-calc(3) rem-calc(6);
        margin-top: rem-calc(10);
        margin-right: rem-calc(10);
        line-height: $global-lineheight;
        font-size: $global-font-size;
        width: auto;
        transition: none;
    }

    &__placeholder {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: rem-calc(13) rem-calc(16);
        border: 1px solid transparent;
        color: rgba(map-deep-get($colors, "black", "default"), 0.6);
        user-select: none;
    }

    &__form {
        position: relative;
    }

    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(map-deep-get($colors, "white", "default"), 0.5);
        z-index: 500;
    }

    &__email {
        max-height: 0;
        overflow: hidden;
        transition: $transition-default;

        &--visible {
            max-height: 1000px;
        }
    }
}
