.account-orders {
    padding: 24px;
    margin: 0 auto;

    @include breakpoint(medium) {
        width: 80%;
    }
    @include breakpoint(xxlarge) {
        padding: 56px;
    }

    &__title {
        margin-bottom: rem-calc(24);
        margin-top: rem-calc(48);
    }
}