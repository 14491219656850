#accountmanager-activities {
    height: 100%;
}

.accountmanager-activities {
    $el: &;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    min-height: rem-calc(500);
    background-color: map-deep-get($colors, "blue", "lightest");
    z-index: 15;
    transform: translateX(100%);
    transition: $transition-default;
    padding: rem-calc(12);

    &--open {
        transform: translateX(0%);
    }

    @include breakpoint(medium) {
        padding: rem-calc(20);
    }

    @include breakpoint(xlarge) {
        transform: translateX(0%);
        position: relative;
        z-index: 10;
    }

    &__container {
        height: 100%;
        overflow: auto;
    }

    &__loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(map-deep-get($colors, "blue", "lightest"), 0.9);
        text-align: center;
        padding-top: rem-calc(250);
        font-weight: 700;
        z-index: 11;
    }

    &__close-button {
        position: fixed;
        top: rem-calc(12);
        right: rem-calc(12);

        @include breakpoint(medium) {
            top: rem-calc(20);
            right: rem-calc(20);
        }

        @include breakpoint(xlarge) {
            &:not(.accountmanager-activities__close-button--customer) {
                display: none;
            }
        }
    }

    &__header {
        padding-right: rem-calc(50);

        @include breakpoint(xlarge) {
            padding-right: 0;
        }
    }

    &__customer {
        padding: rem-calc(15);
        background-color: map-deep-get($colors, "white", "default");
        margin: rem-calc(30) 0;

        &-button {
            margin-top: rem-calc(12);

            @include breakpoint(medium) {
                margin-top: 0;
            }
        }
    }

    &__filter {
        overflow: hidden;
    }
}
