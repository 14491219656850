﻿//
// Breakpoints
// Make sure these are the same as foundation's breakpoints!
$breakpoints: ("small": 0, "medium": 640px, "large": 1024px, "xlarge": 1200px, "xxlarge": 1440px);

//
// Colors
$colors: (
    "white": ("default": #ffffff),
    "black": ("default": #000000),
    "gray": ("darker": #979797, "dark": #bdbdbd, "default": #cccccc, "light": #ebeeed, "lighter": #f5f5f5),
    "blue": (
        "default": #008c95,
        "light": #71bcc1,
        "lighter": #a3d3d6,
        "lightest": #d2e9eb
    ),
    "orange": ("default": #ff7f32),
    "green": (
        "default": #77c19a,
        "light": #a2d4ba,
        "lighter": #e6f3ec,
        "lightest": #f3f9f5
    ),
    "validation": ("valid": #55b890, "invalid": #e54d7a, "valid-dark": darken(#55b890, 25%), "invalid-dark": darken(#e54d7a, 25%)),
    "social": ("facebook": #3b5998, "twitter": #1da1f2, "instagram": #c13584, "youtube": #ff0000, "linkedin": #0077b5),
    "out_of_stock": ("default": #aa0000)
);

/*
 * blue
 * 100 -> #008C95
 * 90 -> #1C99A0
 * 80 -> #3AA4AB
 * 70 -> #57B0B6
 * 60 -> #71BCC1
 * 50 -> #8BC7CC
 * 40 -> #A3D3D6
 * 30 -> #BCDEE1
 * 20 -> #D2E9EB
 * 10 -> #E9F5F6
 */
//
// Fonts
@import url("https://fonts.googleapis.com/css?family=Exo+2:300,400,700,900&display=swap");
$font-default: "Exo 2", sans-serif;

//
// Transition defaults
$transition-default-smoothing: ease-in-out;
$transition-default-time: .3s;
$transition-default: all $transition-default-time $transition-default-smoothing;

$transition-cubic-smoothing: cubic-bezier(.2, 0, 0, 1);
$transition-cubic-time: .2s;
$transition-cubic: all $transition-cubic-time $transition-cubic-smoothing;

//
// Box Shadow
$box-shadow-default: 0 5px 6px 0 rgba(map-deep-get($colors, "black", "default"), .3);
$box-shadow-inverted: 0 -5px 6px 0 rgba(map-deep-get($colors, "black", "default"), .3);
$box-shadow-around: 0 0 15px 0 rgba(map-deep-get($colors, "black", "default"), .2);

//
// Border Radius
$border-radius-default: 2px;

//
// Pagination (boostrap..sorry)
$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;

$pagination-color:                  map-deep-get($colors, "blue", "default") !default;
$pagination-bg:                     map-deep-get($colors, "white", "default") !default;
$pagination-border-width:           1px !default;
$pagination-border-color:           map-deep-get($colors, "gray", "default") !default;

$pagination-focus-box-shadow:       0 0 0 .2rem map-deep-get($colors, "green", "default") !default;
$pagination-focus-outline:          0 !default;

$pagination-hover-color:            map-deep-get($colors, "green", "default") !default;
$pagination-hover-bg:               map-deep-get($colors, "gray", "light") !default;
$pagination-hover-border-color:     map-deep-get($colors, "gray", "default") !default;

$pagination-active-color:           map-deep-get($colors, "white", "default") !default;
$pagination-active-bg:              map-deep-get($colors, "green", "default") !default;
$pagination-active-border-color:    $pagination-active-bg !default;

$pagination-disabled-color:         map-deep-get($colors, "gray", "dark") !default;
$pagination-disabled-bg:            map-deep-get($colors, "white", "default") !default;
$pagination-disabled-border-color:  map-deep-get($colors, "gray", "default") !default;
