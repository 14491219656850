.login-form {
    &__plain-link {
        margin-top: rem-calc(16);
        display: inline-block;
        color: map-deep-get($colors, "blue", "default");
    }

    &__title {
        margin-bottom: rem-calc(32);
    }
}
