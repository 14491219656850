table {
    border: 1px solid map-deep-get($colors, "green", "lightest");
    border-radius: $border-radius-default;
    border-collapse: collapse;
    margin-top: rem-calc(40);
    width: 100%;
}

th {
    background-color: map-deep-get($colors, "green", "lightest");
    padding: 8px;
}

td {
    padding: 8px;
}

tr {
    border-bottom: 1px solid map-deep-get($colors, "green", "lightest");

    &:last-of-type {
        border-bottom: none;
    }
}
