.inspiration-card {
    border-radius: $border-radius-default;
    position: relative;
    transition: $transition-default;
    overflow: hidden;
    $inspiration-card: &;
    margin: rem-calc(8) 0 rem-calc(16);
    display: block;
    color: map-deep-get($colors, "black", "default");

    @include breakpoint(medium) {
        margin: rem-calc(20) 0;
    }

    &__bg {
        height: 40vh;
        max-height: rem-calc(250);
        width: 100%;
        background-position: center;

        &--cover {
            background-size: cover;
            background-color: map-deep-get($colors, "gray", "default");
        }

        &--contain {
            background-size: contain;
            background-repeat: no-repeat;
        }

        @include breakpoint(medium) {
            height: auto;
            max-height: none;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: transparent;
        }
    }

    &__topcontent {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 3;
        display: flex;
        align-items: center;
        color: map-deep-get($colors, "green", "default");

        @include breakpoint(medium) {
            padding: 15px;
        }

        @include breakpoint(xlarge) {
            padding: 30px;
        }
    }

    &__topicon {
        margin-right: rem-calc(8);
        height: rem-calc(20);
        width: auto;
    }

    &__controls {
        position: relative;
        transition: all .5s $transition-cubic-smoothing;
        z-index: 10;
        transform: translateY(-30px);
        opacity: 0;

        svg {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__body {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        opacity: 0;
        transition: all .5s $transition-cubic-smoothing;
        background-color: rgba(map-deep-get($colors, "black", "default"), .5);

        @include breakpoint(medium) {
            padding: 15px;
            height: rem-calc(390);
        }

        @include breakpoint(xlarge) {
            padding: 30px;
        }
    }

    &__content {
        position: relative;
        transition: all .5s $transition-cubic-smoothing;
        margin-top: rem-calc(8);
        width: 100%;
        max-height: 90%;
        height: auto;
        display: flex;
        flex-direction: column;

        @include breakpoint(medium) {
            margin-top: 0;
            transform: translateY(30px);
            opacity: 0;
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__title,
    &__text,
    &__read-more {
        width: 100%;
    }

    &__title,
    &__text {
        margin: 1rem 0 0;
    }

    &__title {
        font-weight: 700;
        border: none;

        @include breakpoint(medium) {
            color: map-deep-get($colors, "white", "default");
        }

        &:focus {
            border: none;
        }
    }

    &__text {
        position: relative;
        overflow: hidden;
        flex-shrink: 1;
    }

    &__read-more {
        margin: rem-calc(16) 0 0 0;
        display: flex;
        align-items: center;
        color: map-deep-get($colors, "green", "default");
        cursor: pointer;

        svg {
            width: rem-calc(20);
            fill: map-deep-get($colors, "green", "default");
        }
    }

    &__save-button {
        margin-left: rem-calc(8);
    }

    &__delete-button {
        background-color: map-deep-get($colors, "validation", "invalid");

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "validation", "invalid-dark");
        }
    }

    &__input {
        width: 100%;
        max-height: 75%;

        &::placeholder {
            color: rgba(map-deep-get($colors, "white", "default"), 0.5);
        }
    }

    &__overlay-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }

    @include breakpoint(medium) {
        &:hover,
        &:focus,
        &--active {
            box-shadow: $box-shadow-default;

            #{$inspiration-card}__body {
                opacity: 1;
            }

            #{$inspiration-card}__content {
                transform: translateY(0);
                opacity: 1;
            }
        }

        &--active {
            #{$inspiration-card}__controls:not(#{$inspiration-card}__controls--on-hover) {
                transform: translateY(0);
                opacity: 1;
            }

            #{$inspiration-card}__overlay-link:not(#{$inspiration-card}__overlay-link--on-hover) {
                display: none;
            }
        }

        &:hover,
        &:focus {
            #{$inspiration-card}__controls--on-hover {
                transform: translateY(0);
                opacity: 1;
            }
        }

        @media (pointer: coarse) {
            box-shadow: $box-shadow-default;

            #{$inspiration-card}__body {
                opacity: 1;
            }

            #{$inspiration-card}__text {
                display: none;
            }

            #{$inspiration-card}__content {
                transform: translateY(0);
                opacity: 1;
            }

            #{$inspiration-card}__controls:not(#{$inspiration-card}__controls--on-hover) {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    &--active {
        cursor: move;

        #{$inspiration-card}__controls {
            opacity: 1;
        }
    }

    &--small {
        #{$inspiration-card}__text {
            //display: none;
        }

        #{$inspiration-card}__subtitle, #{$inspiration-card}__read-more {
            font-size: rem-calc(14);
            line-height: 1.2;
        }
    }

    &--pushTop {
        margin-top: rem-calc(24);

        @include breakpoint(medium) {
            margin-top: 0;
        }
    }

    &--pullTop {
        margin-top: rem-calc(-24);

        @include breakpoint(medium) {
            margin-top: 0;
        }
    }

    &--green {
        background-color: map-deep-get($colors, "green", "default");

        #{$inspiration-card}__body {
            opacity: 1;
        }

        #{$inspiration-card}__read-more {
            color: map-deep-get($colors, "white", "default");

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        #{$inspiration-card}__content {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
