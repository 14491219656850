.contract-variables {
    $me: &;

    &__title {
        margin-bottom: 15px;
    }

    &__row {
        display:flex;
        flex-wrap: wrap;
        margin: 0 -15px;
    }

    &__col-4 {
        padding: 0 15px;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    &__col-6 {
        padding: 0 15px;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .form-group {
        margin-bottom: 15px;
    }

    &__additional-input-label {
        display: inline-block;
        width: 30%
    }
    &__additional-input-amount {
        display: inline-block;
        width: 15%;
    }
    &__additional-input-description {
        display: inline-block;
        width: 40%;
    }
    &__additional {
        margin-bottom: 5px;
        position: relative;
    }

    &__additional-button-delete {
        cursor: pointer;

        svg {
            fill: map-deep-get($colors, "out_of_stock", "default");
            width: 24px;
            height: 24px;
        }
    }

    &__additional-button-add {
        cursor: pointer;

        svg {
            fill: map-deep-get($colors, "green", "default");
            width: 24px;
            height: 24px;
        }
    }



}
