.product-config-item {
    border: 1px solid map-deep-get($colors, "gray", "default");
    margin-bottom: rem-calc(30);
    height: 95%;

    &__image-wrapper {
        height: rem-calc(200);
        position: relative;
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__add {
        width: rem-calc(44);
        height: rem-calc(44);
        margin-right: rem-calc(10);
        margin-top: rem-calc(10);
        line-height: rem-calc(38);
        display: block;
        border-radius: 50%;
        text-align: center;
        background-color: map-deep-get($colors, "green", "lightest");
        color: map-deep-get($colors, "green", "default");
        font-size: rem-calc(35);
        font-weight: 700;
        float: right;
        transition: $transition-default;
        position: relative;
        z-index: 2;

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "green", "default");
            color: map-deep-get($colors, "green", "lightest");
        }
    }

    &__bg {
        position: absolute;
        left: rem-calc(20);
        right: rem-calc(20);
        top: rem-calc(20);
        bottom: rem-calc(20);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    &__content {
        padding: 20px 10px;
        //text-align: center;
        min-height: rem-calc(70);
    }

    &__price {
        margin-bottom: 0;
    }
}
