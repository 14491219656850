.moodboard-buttons {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    &__button {
        margin: rem-calc(8) 0 0 rem-calc(8);

        @include breakpoint(xlarge) {
            margin-top: 0;
        }
    }
}