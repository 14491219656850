.grid-preview {
    height: 50vh;
    margin-top: rem-calc(32);

    &__title {
        position: relative;
        z-index: 3;
        color: map-deep-get($colors, "white", "default");
    }

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    &__block {
        background-color: map-deep-get($colors, "green", "lightest");
        height: calc(16.6vh - 20px);
        margin: rem-calc(10) 0;
        padding: 20px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        position: relative;
        overflow: hidden;
        border-radius: $border-radius-default;

        &--highlight {
            background-color: map-deep-get($colors, "green", "default");
        }

        &--product {
            background-color: map-deep-get($colors, "white", "default");

            &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: rgba(map-deep-get($colors, "black", "default"), .5);
                z-index: 2;
            }
        }
    }
}