.order-summary {
    &__message {
        border-radius: $border-radius-default;
        padding: rem-calc(12);
        margin: rem-calc(32) 0;
        position: relative;
        display: flex;
        align-items: center;
        min-height: rem-calc(48);
        color: map-deep-get($colors, "white", "default");

        &--error {
            background-color: map-deep-get($colors, "validation", "invalid");
        }

        &--success {
            background-color: map-deep-get($colors, "validation", "valid");
        }
    }

    &__messagetext {
        flex-grow: 1;
    }

    &__messagebutton {
        &:hover {
            background-color: map-deep-get($colors, "blue", "default");
        }
    }

    &--products {
        /* stylelint-disable */
        table {
            td, th {
                &:nth-child(1) {
                    width: 100px;
                    text-align: center !important;
                }
                &:nth-child(3) {
                    min-width: 100px;
                    text-align: right !important;
                }
                &:nth-child(4) {
                    min-width: 100px;
                    text-align: center !important;
                }
                &:nth-child(5) {
                    min-width: 100px;
                    text-align: right !important;
                }

            }
        }


        .product-title {
            font-weight: 700;
        }

        .product-subtitle {
            white-space: normal;
        }
        /* stylelint-enable */
    }
}
