.product-config {
    //margin: rem-calc(60) 0;

    &__remarks {
        width: rem-calc(300);
        margin: rem-calc(24) auto 0;
        font-size: rem-calc(14);
        text-align: center;
        font-weight: 300;
    }
}
