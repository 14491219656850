.quotation {
    position: relative;

    &--loading {
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #008c95;
        color: #fff;
        font-weight: bold;
        z-index: 9999;
        width: 100vw;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
