.fixed-vh-block {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 80px 0;

    @include breakpoint(large) {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 33.3%;
    }

    @include breakpoint(xlarge) {
        width: 40%;
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
        z-index: 1;

        @include breakpoint(large) {
            width: 33.3%;
            position: fixed;
            right: inherit;
        }

        @include breakpoint(xlarge) {
            width: 40%;
        }
    }

    &__bg {
        background-image: url("/images/hero.jpg");
        background-size: cover;
        background-position: center;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        @include breakpoint(large) {
            width: 33.3%;
            position: fixed;
        }

        @include breakpoint(xlarge) {
            width: 40%;
        }
    }

    &__content {
        background-color: rgba(map-deep-get($colors, "blue", "default"), .8);
        color: map-deep-get($colors, "white", "default");
        position: relative;
        z-index: 2;
        margin: 0 10%;
        padding: 24px;

        @include breakpoint(xlarge) {
            margin: 0 15%;
            padding: 42px;
        }
    }

    &__list-item {
        display: flex;
        align-items: center;
        margin: 24px 0;
        font-weight: 600;

        &:first-of-type {
            margin-top: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__check {
        margin-right: rem-calc(24);
        width: rem-calc(55);
        height: rem-calc(55);
        border-radius: 50%;
        background-color: map-deep-get($colors, "white", "default");
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        svg {
            width: rem-calc(32);
            fill: map-deep-get($colors, "blue", "default");
        }
    }
}
