.shoppingcart-options {
    border: 1px solid map-deep-get($colors, "gray", "default");
    padding: 24px 12px;
    border-radius: $border-radius-default;
    margin-top: rem-calc(56);

    @include breakpoint(medium) {
        padding: 24px;
    }

    &__list-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: rem-calc(24);

        @include breakpoint(medium) {
            margin-top: rem-calc(8);
        }
    }

    &__list {
        margin-top: rem-calc(24);
    }

    &__option-price {
        text-align: right;
        font-weight: 700;
        padding-left: 34px;
    }

    &__checkbox-wrapper {
        margin-top: rem-calc(10) !important;
    }

    &__title {
        font-weight: 700;
    }
}
