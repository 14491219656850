.case-logos {
    position: relative;
    z-index: 2;
    margin-top: rem-calc(-40);

    &__content {
        background-color: map-deep-get($colors, "white", "default");
        padding: 16px 32px;
        box-shadow: 0 10px 12px 0 rgba(map-deep-get($colors, "black", "default"), .5);
        text-align: center;

        @include breakpoint(large) {
            padding: 16px 32px 32px;
        }
    }

    &__caption {
        color: map-deep-get($colors, "green", "default");
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        margin: rem-calc(24) rem-calc(-16) 0;

        @include breakpoint(large) {
            flex-wrap: nowrap;
            margin: rem-calc(24) 0 0 0;
        }
    }

    &__link {
        display: inline-block;
    }

    &__list-item {
        margin: rem-calc(32) 0;
        padding: 0 rem-calc(16);
        width: 50%;

        @include breakpoint(large) {
            width: auto;
        }
    }

    &__image {
        width: auto;
    }
}