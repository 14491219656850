.floorplan {
    position: relative;
    margin-top: rem-calc(32);

    &__pointers {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__pointer {
        position: absolute;

        &--active {
            z-index: 10;
        }
    }

    &__pointer-icon {
        transition: $transition-default;

        &--dragging {
            padding: 30vh 30vw;
            margin-left: -30vw;
            margin-top: -30vh;
        }

        &--faded {
            opacity: 0.5;
        }
    }

    &__pointer-number {
        position: absolute;
        top: 0;
        left: 0;
        color: #ffffff;
        text-shadow: 1px 1px 2px #000000, -1px -1px 2px #000000, 1px -1px 2px #000000, -1px 1px 2px #000000;
        width: 24px;
        text-align: center;
        z-index: 3;
        pointer-events: none;
    }

    &__pointer-image-wrapper {
        text-align: center;
    }

    &__pointer-image {
        max-height: rem-calc(200);
        margin-bottom: rem-calc(16);
    }

    &__image {
        width: 100%;
    }

    &__pointer-edit {
        width: rem-calc(40);
        height: rem-calc(40);
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
        float: right;

        svg {
            margin-left: 0;
            margin-right: 0;
            width: rem-calc(18);
            height: rem-calc(18);
        }

        svg path {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__pointer-content {
        position: absolute;
        padding: 24px 16px;
        opacity: 0;
        display: none;
        background-color: map-deep-get($colors, "white", "default");
        box-shadow: $box-shadow-around;
        border-radius: 20px;
        width: rem-calc(270);
        transform: translateY(25%);
        transition: $transition-default;

        &::before {
            content: "";
            border-width: 7px;
            border-style: solid;
            position: absolute;
            width: 0;
            height: 0;
        }

        &--present {
            display: block;
        }

        &--active {
            opacity: 1;
            z-index: 4;
            transform: translateY(0%);
        }

        &--top-left,
        &--top-right {
            margin-bottom: rem-calc(16);
            bottom: 100%;

            &::before {
                bottom: rem-calc(-14);
                border-color: map-deep-get($colors, "white", "default") transparent transparent transparent;
            }
        }

        &--bottom-left,
        &--bottom-right {
            margin-top: rem-calc(16);

            &::before {
                top: rem-calc(-14);
                border-color: transparent transparent map-deep-get($colors, "white", "default") transparent;
            }
        }

        &--top-left,
        &--bottom-left {
            right: rem-calc(-16);

            &::before {
                right: rem-calc(30);
            }
        }

        &--top-right,
        &--bottom-right {
            left: rem-calc(-16);

            &::before {
                left: rem-calc(30);
            }
        }
    }

    &__image-area {
        margin-top: rem-calc(24);
    }
}
