@keyframes rotateLoading {
    0% {
        transform: rotate(0);
    }

    95% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
        width: rem-calc(20);
        height: rem-calc(20);
        animation: rotateLoading 1.5s cubic-bezier(.2, 0, 0, 1) infinite;
    }

    svg path {
        fill: map-deep-get($colors, "blue", "default");
    }

    &__title {
        margin-left: rem-calc(16);
    }

    &--center {
        display: flex;
        width: 100%;
    }
}
