.quotation-list {
    align-items: flex-end;
}

.shoppingcart-delivery-costs {
    position: relative;

    label {
        display: inline-block;
        margin-right: 10px;
        padding-top: 6px;
        text-align: right;
        width: 63%;
    }

    input {
        float: right;
        text-align: right;
        width: 30%;
    }
}

.agreement {
    &__button {
        margin: rem-calc(16) rem-calc(12) rem-calc(16) 0;

        a {
            color: #ffffff;
        }
    }

    &__title {
        margin: rem-calc(16) 0;
    }
}

.agreement {
    &__button {
        a {
            color: #ffffff;
        }
    }

    &__terms {
        flex-direction: column;
    }

    &__summary {
        display: flex;
        justify-content: left;
        align-items: center;

        &--check {
            margin-top: 0.25rem;
            margin-right: 0.5rem;

            svg {
                path {
                    fill:  map-deep-get($colors, "green", "default");
                }
            }
        }
    }
}

.agreement-wrapper {
    margin-top: 2rem;

    .checkbox-wrapper {
        margin-top: rem-calc(16);
    }
}

