.related-knowledge {
    &__text {
        margin-bottom: rem-calc(16);
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem-calc(-10);

        @include breakpoint(large) {
            flex-wrap: nowrap;
        }
    }

    &__item {
        width: 100%;
        padding: 0 10px;
        margin-bottom: rem-calc(20);

        @include breakpoint(large) {
            max-width: 50%;
            margin-bottom: 0;
        }
    }
}