.main {
    //overflow: hidden;
    max-width: 100%;
    min-height: 100vh;
    padding-top: map_get($header-height, "small");

    @include breakpoint(medium) {
        padding-top: map_get($header-height, "medium");
    }

    &--push-top {
        padding-top: map_get($header-height, "small") * 1.5;

        @include breakpoint(medium) {
            padding-top: map_get($header-height, "medium") * 1.5;
        }
    }

    &--pull-top {
        margin-top: -(map_get($header-height, "small"));

        @include breakpoint(medium) {
            margin-top: -(map_get($header-height, "medium"));
        }
    }

    &--vh {
        padding-top: 0;
    }
}

.grid-container {
    padding: 0 20px;

    @include breakpoint(large) {
        padding: 0 30px;
    }
    @include breakpoint(xxxlarge) {
        padding: 0;
    }
}

.grid-container-fluid {
    padding: 0 12px;

    @include breakpoint(medium) {
        padding: 0 20px;
    }
}
