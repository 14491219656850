$header-height: ("small": 60px, "medium": 80px);

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 12px;
    width: 100%;
    z-index: 12;
    transition: $transition-default;
    margin: 0 auto;
    max-width: rem-calc(1920);
    $header: &;

    @include breakpoint(medium) {
        padding: 0 20px;
    }

    &__logo {
        line-height: 1;
        height: rem-calc(60);
        display: flex;
        align-items: center;

        @include breakpoint(large) {
            width: auto;
        }

        svg {
            width: rem-calc(30);
            height: rem-calc(30);

            @include breakpoint(large) {
                width: rem-calc(130);
                height: auto;
            }
            @include breakpoint(xxlarge) {
                width: 100%;
                max-width: rem-calc(180);
            }
        }

        svg g, svg path {
            fill: map-deep-get($colors, "white", "default");
            transition: $transition-default;
        }
    }

    &__logo-image {
        max-width: rem-calc(180);
        max-height: rem-calc(60);
        height: 100%;
    }

    &__left-section, &__right-section {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__right-section {
        justify-content: flex-end;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: map_get($header-height, "small");

        @include breakpoint(medium) {
            height: map_get($header-height, "medium");
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: map_get($header-height, "small");
        background-color: rgba(map-deep-get($colors, "white", "default"), .9);
        transition: $transition-default;

        @include breakpoint(medium) {
            position: static;
            background-color: transparent;
            height: auto;
            justify-content: flex-end;
        }
    }

    &__register {
        white-space: nowrap;
    }

    &__app-button {
        white-space: nowrap;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        border: none;
        padding: 0 8px;
        width: 20%;
        height: 100%;
        background-color: transparent;
        transition: $transition-default;
        color: map-deep-get($colors, "black", "default");
        font-weight: 700;
        text-align: center;
        position: relative;
        flex-shrink: 0;

        svg {
            width: rem-calc(20);
            height: rem-calc(20);
            transition: $transition-default;
        }

        svg, svg path {
            fill: map-deep-get($colors, "black", "default");
        }

        @include breakpoint(medium) {
            width: auto;
            height: rem-calc(40);
            border-radius: 30px;
            color: map-deep-get($colors, "black", "default");
            border: 1px solid map-deep-get($colors, "gray", "dark");
            padding: 8px 16px;
            text-align: center;
            display: inline-flex;
            flex-wrap: nowrap;
            margin-right: rem-calc(16);
        }
        @include breakpoint(xlarge) {
            svg {
                margin-right: rem-calc(8);
            }
        }

        &:hover,
        &:focus,
        &--active {
            background-color: transparent;

            svg, svg path {
                fill: map-deep-get($colors, "blue", "default");

                @include breakpoint(medium) {
                    fill: map-deep-get($colors, "black", "default");
                }
            }

            @include breakpoint(medium) {
                background-color: map-deep-get($colors, "blue", "lighter");
                border-color: map-deep-get($colors, "blue", "lighter");
            }
        }

        &:last-of-type {
            margin-right: 0;
        }
    }

    &__button-text {
        display: block;
        width: 100%;
        font-size: rem-calc(9);
        margin-top: rem-calc(4);

        @include breakpoint(medium) {
            display: none;
            width: auto;
            margin-top: 0;
            font-size: rem-calc(16);
        }
        @include breakpoint(xlarge) {
            display: inline;
        }

        &--hide-xlarge {
            @include breakpoint(xlarge) {
                display: none;
            }
        }
    }

    &__cart-num {
        display: none;
        background-color: map-deep-get($colors, "orange", "default");
        border-radius: 50%;
        color: map-deep-get($colors, "white", "default");
        width: rem-calc(20);
        height: rem-calc(20);
        font-size: rem-calc(8);
        margin-left: rem-calc(8);
        top: rem-calc(10);

        @include breakpoint(medium) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @include breakpoint(xlarge) {
            margin-left: 0;
        }
    }

    &__login {
        display: inline-flex;
        align-items: center;
        color: map-deep-get($colors, "white", "default");
        transition: $transition-default;
        margin-right: rem-calc(16);

        svg {
            fill: map-deep-get($colors, "white", "default");
            width: rem-calc(20);
            transition: $transition-default;
        }
    }

    &__account {
        color: map-deep-get($colors, "black", "default");
        font-weight: 700;
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        align-content: center;
        text-align: center;
        font-size: rem-calc(9);
        padding: 0 8px;

        @include breakpoint(medium) {
            font-size: rem-calc(16);
            width: auto;
            flex-wrap: nowrap;
            padding: 0;
        }
    }

    &__account-profile {
        color: map-deep-get($colors, "white", "default");
        width: rem-calc(20);
        height: rem-calc(20);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        text-align: center;
        background-color: map-deep-get($colors, "blue", "default");
        font-size: rem-calc(10);

        svg {
            width: rem-calc(10);
            height: auto;

            @include breakpoint(medium) {
                width: rem-calc(20);
            }
        }

        svg path, svg ellipse {
            stroke: map-deep-get($colors, "white", "default");
        }

        @include breakpoint(medium) {
            width: rem-calc(40);
            height: rem-calc(40);
            font-size: rem-calc(26);
            margin-right: rem-calc(8);
        }
    }

    &__account-profile-text {
        display: block;
        width: 100%;
        margin-top: rem-calc(4);

        @include breakpoint(medium) {
            display: none;
            margin-top: 0;
        }
        @include breakpoint(large) {
            display: inline;
            width: auto;
        }
    }

    &--menuExpanded {
        #{$header} {
            &__list {
                opacity: 1;
                transform: translateX(0);
                transition: opacity .3s ease-in-out, transform 0s linear;
            }

            &__listItem, &__subListItem {
                transform: translateY(0);
                opacity: 1;

                @for $i from 1 through 10 {
                    &:nth-child(#{$i}) {
                        transition-delay: $i * .05s;
                    }
                }
            }
        }
    }

    &--scrolled {
        box-shadow: $box-shadow-default;

        #{$header}__buttons {
            box-shadow: $box-shadow-inverted;

            @include breakpoint(medium) {
                box-shadow: none;
            }
        }
    }

    &--inverted {
        background-color: map-deep-get($colors, "white", "default");

        svg g {
            fill: map-deep-get($colors, "blue", "default");
        }

        #{$header}__logo {
            svg, svg path {
                fill: map-deep-get($colors, "blue", "default");
            }
        }

        #{$header}__login {
            color: map-deep-get($colors, "black", "default");

            svg {
                fill: map-deep-get($colors, "black", "default");
            }
        }
    }

    &--hide {
        top: -(map_get($header-height, "small"));
        box-shadow: none;

        #{$header}__buttons {
            bottom: -(map_get($header-height, "small"));
            box-shadow: none;
        }

        @include breakpoint(medium) {
            top: -(map_get($header-height, "medium"));
        }
    }

    @media print {
        display: none;
    }
}
