﻿/// <reference path="../_reference.scss" />

*, *::before, *::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html, body {
    margin: 0 auto;
    padding: 0;
    font-family: $font-default;
}

.body {
    max-width: rem-calc(1920);

    &--overflow-hidden {
        overflow: hidden;
    }
}

.rich-text-area ul {
    margin: 0;
    padding: 0;
    list-style-type: disc;
    margin-block-end: 1em;
    margin-inline-start: 1em;

}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

a {
    text-decoration: none;
}

b, strong {
    font-weight: 700;
}

.icon-link {
    color: map-deep-get($colors, "blue", "default");
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    flex-shrink: 0;

    svg {
        fill: map-deep-get($colors, "blue", "default");
        height: 16px;
        width: auto;
        margin-right: rem-calc(8);
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

::selection {
    background-color: map-deep-get($colors, "green", "default");
    color: map-deep-get($colors, "white", "default");
}
