.step-block {
    position: relative;
    z-index: 2;
    padding: 120px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    float: left;
    $step-block: &;

    @include breakpoint(medium) {
        flex-wrap: nowrap;
    }
    @include breakpoint(large) {
        margin: rem-calc(80) 0;
        padding: 80px 0;
    }

    @include breakpoint(large) {
        &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: rem-calc(20);
            display: block;
            background-color: map-deep-get($colors, "green", "light");
        }
    }

    &::before {
        content: "";
        position: absolute;
        right: rem-calc(-30);
        left: 20%;
        bottom: 0;
        height: rem-calc(20);
        display: block;
        background-color: map-deep-get($colors, "green", "light");

        @include breakpoint(large) {
            right: 0;
            left: 40%;
        }
    }

    &__content {
        margin-top: rem-calc(40);
        width: 100%;

        @include breakpoint(medium) {
            margin: 0;
        }
        @include breakpoint(large) {
            padding-right: rem-calc(60);
        }
    }

    &__image {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__img {
        width: 100%;

        @include breakpoint(xlarge) {
            width: auto;
        }
    }

    &__title {
        margin: rem-calc(-40) 0 0 0;
    }

    &__button {
        margin-top: rem-calc(16);
    }

    // Rich Text area
    /* stylelint-disable */
    &__text {
        p {
            margin: rem-calc(16) 0 0 0;
        }
    }
    /* stylelint-enable */

    &--odd {
        #{$step-block}__content {
            padding-left: 0;

            @include breakpoint(medium) {
                padding-left: 40px;
            }
            @include breakpoint(large) {
                padding-left: 0;
            }
        }
    }

    &--even {
        flex-direction: row-reverse;

        #{$step-block}__content {
            margin-left: 0;

            @include breakpoint(large) {
                margin-left: rem-calc(56);
            }
        }

        &::after {
            right: inherit;
            left: 0;
        }

        &::before {
            left: rem-calc(-30);
            right: 20%;

            @include breakpoint(large) {
                left: 0;
                right: 40%;
            }
        }
    }
}
