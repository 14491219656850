.green-section {
    background-color: map-deep-get($colors, "green", "lightest");
    padding: 56px 20px;
    min-height: calc(100vh - #{map_get($header-height, "small")});
    height: 100%;

    @include breakpoint(medium) {
        min-height: calc(100vh - #{map_get($header-height, "medium")});
        padding: 56px;
    }

    &__title {
        margin-bottom: rem-calc(24);
        margin-top: rem-calc(48);

        &--nomargin {
            margin-top: 0;
        }
    }

    &__divider {
        margin-top: rem-calc(24);
        margin-bottom: rem-calc(24);
    }
}