﻿//BEWARE: YOU SHOULD NEVER NEED THIS. IF YOU DO, YOU SHOULD HAVE A GREAT EXPLANATION.

#click-image1 {
    transition: opacity 0.2s ease-in-out;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: #FFFFFF;

    &:hover {
        opacity: 0.2;
    }
}

//#livechat-compact-container {
//    display: none;
//}