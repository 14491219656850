.side-navigation {
    margin-bottom: rem-calc(64);

    &__list-link {
        color: map-deep-get($colors, "black", "default");
        display: flex;
        align-items: center;

        svg {
            fill: map-deep-get($colors, "blue", "default");
            height: rem-calc(16);
            width: auto;
            margin-right: rem-calc(8);
        }
    }

    &--push-top {
        margin-top: rem-calc(32);
    }
}
