.list-filters {
    $el: &;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &__section {
        margin-top: rem-calc(32);
    }

    &__archive {
        margin-top: rem-calc(32);
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 30px;
        min-height: 40px;
        padding: 0 20px;
        border: 1px solid map-deep-get($colors, "gray", "default");
        color: map-deep-get($colors, "gray", "default");
        font-weight: 700;

        span:not(:last-child) {
            margin-right: 3px;
        }

        svg {
            width: rem-calc(25);
            height: rem-calc(25);
            float: left;

            path {
                fill: map-deep-get($colors, "gray", "default");
            }
        }

        &--active {
            border: 1px solid map-deep-get($colors, "orange", "default");
            color: map-deep-get($colors, "orange", "default");

            svg {
                path {
                    fill: map-deep-get($colors, "orange", "default");
                }
            }
        }
    }
}
