.vh-slider {
    position: relative;
    height: 100%;

    &__slide {
        width: 100%;
        position: relative;
        height: 40vh;

        @include breakpoint(large) {
            height: 100%;
            min-height: 100vh;
        }
    }

    &__slide-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
    }

    // Flickity is NPM package, geen controle over classnames
    /* stylelint-disable */
    .flickity-page-dots {
        bottom: rem-calc(24);

        .dot {
            background-color: map-deep-get($colors, "blue", "default");
            border: 2px solid map-deep-get($colors, "blue", "default");
            opacity: 1;
            width: rem-calc(16);
            height: rem-calc(16);

            &.is-selected {
                background-color: map-deep-get($colors, "green", "lightest");
            }
        }
    }
    /* stylelint-enable */
}
