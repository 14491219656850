.product-config-scenarios {
    display: flex;
    flex-wrap: wrap;
    margin: rem-calc(10) rem-calc(-10) 0;
    $product-config-scenarios: &;

    @include breakpoint(medium) {
        flex-wrap: nowrap;
    }

    &__item {
        color: map-deep-get($colors, "black", "default");
        padding: 0 10px;
        width: 100%;
        margin-bottom: rem-calc(30);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }

        &:hover,
        &:focus {
            #{$product-config-scenarios}__icon {
                background-color: map-deep-get($colors, "green", "lighter");
            }
        }

        &--active {
            #{$product-config-scenarios}__icon {
                border: 4px solid map-deep-get($colors, "blue", "default");
            }
        }
    }

    &__item-title {
        width: 100%;
        margin-top: rem-calc(20);
    }

    &__icon {
        margin-top: rem-calc(20);
        width: rem-calc(100);
        height: rem-calc(100);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: map-deep-get($colors, "green", "lightest");
        border: 4px solid map-deep-get($colors, "green", "lightest");
        transition: $transition-default;

        svg {
            max-width: rem-calc(60);
            max-height: rem-calc(60);
            height: 100%;
            width: 100%;
        }
    }
}
