.hero {
    padding-top: map_get($header-height, "small");
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: map-deep-get($colors, "white", "default");
    height: 85vh;
    min-height: rem-calc(600);
    position: relative;
    z-index: 1;

    @include breakpoint(medium) {
        padding-top: map_get($header-height, "medium");
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .6);
        z-index: 2;
    }

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-position: center;
        background-size: cover;
        background-color: map-deep-get($colors, "gray", "default");
    }

    &__content {
        position: relative;
        width: 100%;
        z-index: 3;
    }

    &__button {
        margin-top: rem-calc(32);
    }

    &--small {
        height: auto;
        min-height: rem-calc(400);

        @include breakpoint(medium) {
            min-height: rem-calc(600);
        }
    }
}