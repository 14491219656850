.shoppingcart-additional-costs {
    margin-top: rem-calc(56);

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: rem-calc(8);

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__label {
        padding-right: 24px;
    }

    &__value {
        font-weight: 700;

        input {
            font-weight: 700;
        }
    }
}
