.usps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0 8px;
    margin: rem-calc(8) 0 rem-calc(24);
    width: 100%;
    $usps: &;

    @include breakpoint(medium) {
        padding: 0 32px;
    }
    @include breakpoint(large) {
        flex-wrap: nowrap;
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 50%;
        margin: rem-calc(24) 0;

        @include breakpoint(large) {
            width: auto;
        }
    }

    &__icon {
        width: rem-calc(44);
        height: rem-calc(44);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(map-deep-get($colors, "green", "default"), .2);

        svg {
            width: rem-calc(15);
            height: auto;
        }
    }

    &__title {
        font-size: rem-calc(14);
        font-weight: 700;
        position: relative;
        z-index: 1;
        margin-top: rem-calc(-16);
        display: block;
        width: 100%;
        text-align: center;
    }

    &--vh {
        margin: 0;
        left: 0;
        right: 0;
        top: 0;
        z-index: 2;
        background-color: map-deep-get($colors, "white", "default");

        #{$usps}__item {
            margin: rem-calc(16) 0;
        }

        @include breakpoint(large) {
            position: absolute;
            box-shadow: $box-shadow-default;
        }
    }
}