.product-config-title {
    text-align: center;
    margin: rem-calc(30) auto;
    $product-config-title: &;

    &__title {
        margin: rem-calc(20) auto;
        display: inline;
    }

    &__input {
        max-width: rem-calc(600);
        text-align: center;
        //margin: rem-calc(20) auto;
    }

    a {
        cursor: pointer;
        padding-left: rem-calc(20);
    }
}
