.shoppingcart-controls {
    margin-top: rem-calc(32);

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__button {
        margin-left: rem-calc(16);
    }

    &__button-wrapper {
        display: inline-flex;

        &:only-child {
            align-self: flex-end;
            margin-left: auto;
            width: auto;
        }
    }

    &__button:only-child {
        align-self: flex-end;
        margin-left: auto;
        width: auto;
    }
}
