.search-bar {
    position: relative;
    margin: 0 0 0 rem-calc(16);
    width: 100%;
    $search-bar: &;

    @include breakpoint(medium) {
        margin: 0 rem-calc(16);
    }

    &__label {
        position: absolute;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 12px 0 24px;
        cursor: pointer;

        svg {
            width: rem-calc(18);
            height: auto;
        }
    }

    &__overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
        opacity: 0;
        transition: opacity .3s ease-in-out, margin 0s linear .3s;

        @include breakpoint(medium) {
            top: map-get($header-height, "medium");
        }
    }

    &__input {
        text-indent: rem-calc(40);
        border-radius: rem-calc(20);
    }

    &__select-wrapper {
        position: absolute;
        right: 0;
        top: 0;
        width: rem-calc(130);
    }

    &__select {
        border-radius: 0 rem-calc(20) rem-calc(20) 0;
        padding-right: 36px;
        font-size: rem-calc(14);
    }

    &__results {
        position: fixed;
        left: 0;
        right: 0;
        top: map-get($header-height, "small");
        background-color: map-deep-get($colors, "white", "default");
        padding: 16px 0 8px;
        max-height: 50vh;
        overflow: auto;
        opacity: 0;
        margin-left: -9999px;
        transition: opacity .3s ease-in-out, margin 0s linear .3s;

        @media (pointer: coarse) {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }

        @include breakpoint(medium) {
            top: map-get($header-height, "medium");
        }
        @include breakpoint(large) {
            position: absolute;
            top: 100%;
            border-radius: $border-radius-default;
        }
    }

    &__results-item {
        padding: 8px 16px;
    }

    &__results-link {
        color: map-deep-get($colors, "black", "default");
        transition: color .3s ease-in-out;

        &:hover,
        &:focus {
            color: map-deep-get($colors, "green", "default");
        }
    }

    &--show {
        #{$search-bar}__results {
            margin-left: 0;
            transition: opacity .3s ease-in-out, margin 0s linear;
            opacity: 1;
        }

        #{$search-bar}__overlay {
            opacity: 1;
            bottom: 0;
            transition: opacity .3s ease-in-out, top .3s ease-in-out;
        }
    }
}
