.shoppingcart-price {
    margin-top: rem-calc(56);

    &__content {
        margin-top: rem-calc(16);
        background-color: map-deep-get($colors, "green", "lightest");
        padding: 24px;
        border-radius: $border-radius-default;
    }

    &__divider {
        height: rem-calc(1);
        width: 100%;
        background-color: map-deep-get($colors, "gray", "default");
        margin: rem-calc(16) 0;
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: rem-calc(8);

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__label {
        padding-right: 24px;
    }

    &__description {
        font-style: italic;
        font-size: 0.8em;
        max-width: 80%;
        width: 80%;

        /* stylelint-disable */
        a {
            color: map-deep-get($colors, "green", "default");
        }
        /* stylelint-enable */
    }

    &__value {
        font-weight: 700;

        input {
            font-weight: 700;
        }
    }
}
