.empty-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-default;
    background-color: map-deep-get($colors, "white", "default");
    border: 1px dashed map-deep-get($colors, "green", "default");
    padding: 15px;
    max-height: rem-calc(250);
    height: rem-calc(390);
    transition: $transition-default;

    @include breakpoint(medium) {
        max-height: none;
    }

    &__title {
        margin-bottom: rem-calc(25);
    }

    &__content {
        text-align: center;
        color: map-deep-get($colors, "green", "default");
    }

    &:hover,
    &:focus {
        background-color: map-deep-get($colors, "green", "lightest");
    }
}
