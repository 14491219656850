.knowledge-item {
    display: block;
    color: map-deep-get($colors, "black", "default");
    $knowledge-item: &;

    &__image-wrapper {
        height: rem-calc(380);
        position: relative;

        &::before {
            content: "";
            z-index: 2;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(map-deep-get($colors, "black", "default"), .5);
            opacity: 0;
            transition: $transition-default;
        }
    }

    &__bg {
        border-radius: $border-radius-default;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
    }

    &__content {
        padding: 24px 12px;
        border: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__read-more {
        position: absolute;
        right: rem-calc(12);
        bottom: rem-calc(8);
        color: map-deep-get($colors, "green", "default");
        display: inline-flex;
        align-items: center;
        opacity: 0;
        transform: translateY(10px);
        transition: $transition-default;
        z-index: 2;

        svg {
            width: rem-calc(18);
            fill: map-deep-get($colors, "green", "default");
        }
    }

    &__button {
        margin-top: rem-calc(16);
    }

    &__categories {
        margin-bottom: rem-calc(8);
    }

    &__category {
        background-color: map-deep-get($colors, "green", "lightest");
        color: map-deep-get($colors, "green", "default");
        padding: 4px 8px;
        display: inline-block;
        margin: 0 rem-calc(8) rem-calc(8) 0;
    }

    &:hover,
    &:focus {
        #{$knowledge-item}__image-wrapper {
            &::before {
                opacity: 1;
            }
        }

        #{$knowledge-item}__read-more {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &--overview {
        margin-bottom: rem-calc(20);
    }
}