.product-information {
    border: 1px solid map-deep-get($colors, "gray", "default");
    padding: 32px;
    margin: rem-calc(40) 0;

    @include breakpoint(medium) {
        margin-top: 0;
    }

    &__title {
        margin-bottom: rem-calc(24);
    }
}