.product-config-selected-filters {
    padding: 20px 0 10px;
    border-top: 1px solid map-deep-get($colors, "gray", "default");
    border-bottom: 1px solid map-deep-get($colors, "gray", "default");

    @include breakpoint(large) {
        margin: 0 rem-calc(-20);
        padding: 20px;
    }
    @include breakpoint(xlarge) {
        margin: 0 rem-calc(-50);
        padding: 20px 50px;
    }

    &__title {
        font-weight: 700;
        margin-bottom: rem-calc(10);
    }

    //&__content {
    //    display: flex;
    //    flex-wrap: wrap;
    //    align-items: center;
    //}

    &__item {
        margin-bottom: rem-calc(10);
    }

    &__filter {
        margin: 0 rem-calc(10);
        background-color: map-deep-get($colors, "green", "lightest");
        color: map-deep-get($colors, "green", "default");
        padding: 5px 10px;
        display: inline-block;
    }

    &__link {
        color: map-deep-get($colors, "black", "default");
        text-decoration: underline;
        font-weight: 400;
        display: inline-block;
    }
}
