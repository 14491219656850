.inspiration-overview {
    margin: rem-calc(32) 0 rem-calc(40);

    &__list {
        margin: 0 -.625rem;
        width: calc(100% + (.625rem * 2));
    }

    &__controls {
        align-items: center;
        margin-bottom: rem-calc(16);
        overflow: hidden;
    }

    &__search-results {
        margin-bottom: rem-calc(40);
    }

    &__title {
        float: left;
        margin-top: rem-calc(6);
    }

    &__load-more {
        margin-top: rem-calc(40);
        display: flex;
        flex-wrap: wrap;
    }

    &__text {
        display: flex;
        align-items: center;
        margin-bottom: rem-calc(16);
    }

    &__edit {
        float: right;
        min-height: 42px;

        &:not(.button) {
            color: map-deep-get($colors, "green", "default");
            text-decoration: underline;
            text-transform: lowercase;
            padding: 8px 24px;
            min-height: rem-calc(40);
            display: inline-block;
            border-radius: 30px;
            border: 1px solid transparent;
        }
    }

    &__icon {
        margin-right: rem-calc(5);
        height: rem-calc(14);
        width: auto;
    }

    &__button {
        margin: 0 rem-calc(16) rem-calc(16) 0;
    }

    &--push-bottom {
        margin-bottom: rem-calc(80);
    }

    &--push-top {
        @include breakpoint(medium) {
            margin: rem-calc(130) 0 0 0;
        }
    }

    &--empty {
        margin: rem-calc(54) 0 0 0;

        @include breakpoint(medium) {
            margin: rem-calc(54) 0 0 0;
        }
    }
}
