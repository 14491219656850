﻿h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 400;
    line-height: 1.2;
}

h1, .h1 {
    font-size: rem-calc(32);
    font-weight: 300;

    @include breakpoint(medium) {
        font-size: rem-calc(48);
    }
    @include breakpoint(large) {
        font-size: rem-calc(70);
    }

    &__bold {
        font-weight: 700;
    }
}

h2, .h2 {
    font-size: rem-calc(32);

    @include breakpoint(medium) {
        font-size: rem-calc(40);
    }
}

h3, .h3 {
    font-size: rem-calc(26);
    font-weight: 300;

    &--bold {
        font-weight: 600;
    }
}

h4, .h4 {
    font-size: rem-calc(18);
    font-weight: 700;

    @include breakpoint(medium) {
        font-size: rem-calc(24);
    }

    &--light {
        font-weight: 300;
    }
}

h5, .h5 {
    font-size: rem-calc(18);
    font-weight: 700;
}

h6, .h6 {
    font-size: rem-calc(14);
    font-weight: 700;
}

.num-large {
    font-size: rem-calc(116);
    font-weight: 900;
    color: map-deep-get($colors, "gray", "default");
    line-height: 1;
}

.caption {
    display: block;
    font-weight: 700;
}

.plain-link {
    transition: $transition-default;
    color: map-deep-get($colors, "blue", "default");
    font-weight: 700;

    &:hover {
        color: map-deep-get($colors, "green", "default");
    }
}
