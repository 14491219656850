.signature {
    &__wrapper {
        width: 500px;
    }

    &__canvas {
        width: 100%;
        aspect-ratio: 4/3;
        border: 1px solid #ccc;
    }
}
