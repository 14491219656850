.aside-filters {
    $elm: &;
    position: fixed;
    z-index: 12;
    left: 0;
    top: 0;
    bottom: 0;
    width: rem-calc(250);
    max-width: 100%;
    transform: translateX(-250px);
    transition: transform .4s cubic-bezier(.2, 0, 0, 1);
    $aside-filters: &;

    @include breakpoint(large) {
        margin: rem-calc(80) 0;
        position: static;
        transform: translateX(0);
    }

    &__close {
        position: absolute;
        right: rem-calc(16);
        top: rem-calc(16);
        z-index: 13;
        cursor: pointer;

        svg {
            width: rem-calc(20);
            height: rem-calc(20);
            fill: map-deep-get($colors, "blue", "default");
        }
    }

    &__reset {
        margin-bottom: rem-calc(20);
        display: inline-block;
    }

    &__bg {
        position: fixed;
        width: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
        opacity: 0;
        transform: translateX(250px);
        transition: opacity .4s ease-in-out, transform .4s cubic-bezier(.2, 0, 0, 1), width 0s linear .4s;
    }

    &__content {
        position: relative;
        z-index: 2;
        background-color: map-deep-get($colors, "white", "default");
        padding: 56px 24px 24px;
        height: 100vh;
        overflow: auto;
        -webkit-overflow-scrolling: touch;

        @include breakpoint(large) {
            border: 1px solid map-deep-get($colors, "gray", "default");
            border-radius: rem-calc(10);
            padding: 24px;
            height: auto;
        }
    }

    &__main-list {
        margin-top: rem-calc(16);
    }

    &__main-list-item {
        margin-top: rem-calc(12);
    }

    &__sub-list {
        margin-top: rem-calc(16);
    }

    &__sub-list-item {
        margin-top: rem-calc(12);
    }

    &__title {
        margin-top: rem-calc(32);

        &--first {
            margin-top: 0;
        }
    }

    &__searched {
        padding-bottom: 16px;
        margin-bottom: rem-calc(16);
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__searched-query {
        font-weight: 700;
        display: block;
    }

    &__range {
        margin-top: rem-calc(16);
    }

    &__range-values {
        display: flex;
        justify-content: space-between;
        line-height: 20px;
        margin-top: rem-calc(12);
    }

    &__range-value-wrapper {
        position: relative;
        max-width: 46%;
    }

    &__range-value {
        height: 20px;
        width: auto;
        min-width: 65px;
        max-width: 100%;
        padding: 3px 0 3px 3px;
        border-color: #ffffff;

        &:not(:disabled) {
            &:focus,
            &:active,
            &:hover {
                border-color: #cccccc;

                & + #{$elm}__range-unit {
                    display: none;
                }
            }
        }
    }

    &__range-unit {
        position: absolute;
        top: 0;
        right: 2px;
    }

    &--active {
        transform: translateX(0);

        #{$aside-filters}__bg {
            opacity: 1;
            width: 100vw;
            transform: translateX(0);
            transition: opacity .4s ease-in-out, transform .4s cubic-bezier(.2, 0, 0, 1);
        }

        #{$aside-filters}__content {
            transform: translateX(0);
        }
    }
}
