.board-card {
    color: map-deep-get($colors, "black", "default");
    margin: rem-calc(20) 0;
    display: block;
    $board-card: &;

    &__bg {
        height: rem-calc(320);
        background-size: cover;
        background-position: center;
        background-color: map-deep-get($colors, "gray", "default");
        padding: 8px;
        overflow: hidden;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: rgba(map-deep-get($colors, "black", "default"), .5);
            opacity: 0;
            z-index: 1;
            transition: $transition-default;
        }
    }

    &__bg-content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-wrap: wrap;
        font-weight: 700;
        color: map-deep-get($colors, "green", "default");
        position: relative;
        z-index: 2;
    }

    &__icon {
        width: rem-calc(44);
        height: rem-calc(44);
        background-color: map-deep-get($colors, "green", "lighter");
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
    }

    &__plus {
        font-size: rem-calc(80);
        line-height: 1;
        display: block;
        width: 100%;
    }

    &__content {
        margin-top: rem-calc(15);
    }

    &__category,
    &__archived {
        margin: 0 0 rem-calc(5);
        padding: 5px 10px;
        display: inline-block;
        text-transform: capitalize;
    }

    &__category {
        background-color: map-deep-get($colors, "green", "lightest");
        color: map-deep-get($colors, "green", "default");
    }

    &__archived {
        background-color: lighten(map-deep-get($colors, "validation", "invalid"), 37%);
        color: map-deep-get($colors, "validation", "invalid");
    }

    &__read-more {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: map-deep-get($colors, "white", "default");
        position: absolute;
        z-index: 2;
        bottom: rem-calc(16);
        right: rem-calc(16);
        font-size: rem-calc(21);
        opacity: 0;
        transform: translateX(-20px);
        transition: $transition-default;
        font-weight: 300;

        svg {
            width: rem-calc(24);
            max-height: rem-calc(19);
            margin-left: rem-calc(16);
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &:hover,
    &:focus {
        #{$board-card}__read-more {
            opacity: 1;
            transform: translateX(0);
        }

        #{$board-card}__bg {
            &::before {
                opacity: 1;
            }
        }
    }

    &--add {
        #{$board-card}__bg {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: $border-radius-default;
            background-color: map-deep-get($colors, "white", "default");
            border: 1px dashed map-deep-get($colors, "green", "default");

            &::before {
                background-color: map-deep-get($colors, "green", "lightest");
            }
        }
    }
}
