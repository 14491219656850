@keyframes fade-new-board-background {
    0% {
        background-color: rgba(map-deep-get($colors, "green", "default"), .5);
    }

    100% {
        background-color: rgba(map-deep-get($colors, "green", "default"), 0);
    }
}

.save-popup {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    margin-left: rem-calc(9999);
    opacity: 0;
    transition: opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear .4s;
    $save-popup: &;

    &__close {
        position: absolute;
        left: rem-calc(-15);
        top: rem-calc(-15);
        z-index: 1;
        border-radius: 50%;
        background-color: map-deep-get($colors, "green", "default");
        width: rem-calc(30);
        height: rem-calc(30);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        svg {
            width: rem-calc(18);
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
    }

    &__title {
        margin-bottom: rem-calc(16);
    }

    &__content-wrapper {
        position: relative;
        z-index: 2;
        max-width: rem-calc(800);
        min-height: rem-calc(550);
        width: calc(100% - 400px);
        display: inline-flex;
        transition: all .4s cubic-bezier(.2, 0, 0, 1);
        transform: translateY(30px);
        opacity: 0;

        &--active {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__main-content {
        background-color: map-deep-get($colors, "white", "default");
        padding: 32px 48px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        width: 100%;
        transition: $transition-default;
    }

    &__aside-content {
        background-color: map-deep-get($colors, "green", "lightest");
        border-left: 1px solid map-deep-get($colors, "gray", "dark");
        padding: 40px 0 0;
        width: rem-calc(400);
        transition: all .6s cubic-bezier(.2, 0, 0, 1);
        transform: translateX(0);
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__image-area {
        position: relative;
        display: inline-block;
        margin-top: rem-calc(32);
    }

    &__image {
        max-height: 70vh;
    }

    &__pointers {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__pointer {
        position: absolute;

        &--active {
            z-index: 3;
        }
    }

    &__pointer-content {
        position: absolute;
        margin-left: 9999px;
        padding: 24px 16px;
        opacity: 0;
        background-color: map-deep-get($colors, "white", "default");
        transform: translateY(30px);
        transition: margin-left 0s linear .4s, transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1);
        box-shadow: $box-shadow-around;
        border-radius: 20px;
        z-index: 4;
        width: rem-calc(270);
        left: rem-calc(-16);
        margin-top: rem-calc(16);
        top: 100%;
        $pointer-content: &;

        &::before {
            content: "";
            border-color: transparent transparent map-deep-get($colors, "white", "default") transparent;
            border-width: 7px;
            border-style: solid;
            position: absolute;
            left: rem-calc(30);
            top: rem-calc(-14);
            width: 0;
            height: 0;
        }

        &--active {
            margin-left: 0;
            opacity: 1;
            transform: translateY(0);
            transition: margin-left 0s linear, transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1);
        }

        &--offset-right {
            left: unset;
            right: rem-calc(-16);

            &::before {
                left: unset;
                right: rem-calc(30);
            }
        }

        &--offset-bottom {
            top: unset;
            bottom: rem-calc(50);

            &::before {
                border-color: map-deep-get($colors, "white", "default") transparent transparent transparent;
                top: unset;
                bottom: rem-calc(-14);
            }
        }
    }

    &__pointer-dropdown {
        margin-top: rem-calc(24);
        width: rem-calc(230);

        /* stylelint-disable */
        .dropdown__results-wrapper {
            width: 30vw;
            min-width: 500px;
            margin-left: -50%;
        }
        /* stylelint-enable */
    }

    &__search-wrapper {
        padding: 0 24px 32px;
        border-bottom: 1px solid map-deep-get($colors, "gray", "dark");
    }

    &__search {
        margin-top: rem-calc(16);
        border-radius: 40px;
        width: rem-calc(240);
    }

    &__save-pointer {
        margin-top: rem-calc(16);
        float: right;
    }

    &__items-title {
        padding: 8px 24px;
        display: block;
    }

    &__items-wrapper {
        margin-right: rem-calc(-18);
        padding-bottom: 80px;
        overflow-y: auto;
        overflow-x: hidden;

        @media (pointer: coarse) {
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
        }
    }

    &__items {
        border-top: 1px solid map-deep-get($colors, "gray", "dark");
        padding-bottom: 8px;

        &:first-of-type {
            border-top: none;
        }
    }

    &__list-item {
        display: flex;
        align-items: center;
        padding: 8px 24px;
        position: relative;
        transition: transform .6s cubic-bezier(.2, 0, 0, 1);
        min-height: rem-calc(80);

        &--new {
            background-color: rgba(map-deep-get($colors, "green", "default"), .5);
            animation: fade-new-board-background 2s ease-in-out 4s forwards;
        }

        &--confirm {
            transform: translateX(100%);
        }
    }

    &__confirm {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 8px 24px;
        z-index: 2;
        transform: translateX(-100%);
    }

    &__confirm-buttons {
        margin-top: rem-calc(8);
    }

    &__confirm-link {
        border-radius: 50%;
        background-color: map-deep-get($colors, "green", "default");
        width: rem-calc(40);
        height: rem-calc(40);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: rem-calc(8);
        transition: background-color .3s ease-in-out;

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "blue", "default");
        }

        svg {
            width: rem-calc(20);
            height: rem-calc(20);
            fill: map-deep-get($colors, "white", "default");
        }

        &--cancel {
            background-color: map-deep-get($colors, "validation", "invalid");

            &:hover,
            &:focus {
                background-color: map-deep-get($colors, "validation", "invalid-dark");
            }
        }
    }

    &__list-link-wrapper {
        position: relative;
        width: 100%;

        &:hover,
        &:focus {
            #{$save-popup}__list-link-external {
                opacity: 1;
            }
        }
    }

    &__list-link-external {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        transition: $transition-default;

        svg {
            width: rem-calc(18);
            height: rem-calc(18);
            transition: $transition-default;
        }

        &:hover,
        &:focus {
            svg {
                fill: map-deep-get($colors, "green", "default");
            }
        }
    }

    &__list-link {
        transition: $transition-default;
        color: map-deep-get($colors, "black", "default");
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;

        &:hover,
        &:focus {
            color: map-deep-get($colors, "green", "default");
        }
    }

    &__list-image {
        width: rem-calc(60);
        margin-right: rem-calc(16);
    }

    &__list-item-title {
        font-size: rem-calc(14);
        text-transform: uppercase;
        font-weight: 700;
    }

    &__new-board-link {
        background-color: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "black", "default");
        height: rem-calc(60);
        overflow: hidden;
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 24px;
        box-shadow: 0 rem-calc(-4) rem-calc(11) 0 rgba(map-deep-get($colors, "black", "default"), .5);
        transition: transform .6s cubic-bezier(.2, 0, 0, 1) .3s, background-color $transition-default-time $transition-default-smoothing, box-shadow $transition-default-time $transition-default-smoothing;
        z-index: 2;

        svg {
            width: rem-calc(18);
            height: rem-calc(18);
            fill: map-deep-get($colors, "green", "default");
            margin-right: rem-calc(16);
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "green", "lighter");
        }
    }

    &__new-board-section {
        background-color: map-deep-get($colors, "white", "default");
        padding: 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100%);
        transition: all .6s cubic-bezier(.2, 0, 0, 1);
    }

    &__cancel {
        display: inline-block;
        color: map-deep-get($colors, "black", "default");
        margin-top: rem-calc(16);

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__new-board-created {
        display: flex;
        align-items: center;
    }

    &__new-board-icon {
        border-radius: 50%;
        background-color: map-deep-get($colors, "blue", "default");
        width: rem-calc(50);
        height: rem-calc(50);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: rem-calc(16);
        flex-shrink: 0;

        svg {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &--expanded {
        #{$save-popup}__content-wrapper {
            margin-left: rem-calc(-400);
        }

        #{$save-popup}__aside-content {
            transform: translateX(100%);
        }
    }

    &--open {
        opacity: 1;
        margin-left: 0;
        transform: translateY(0);
        transition: transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear;
    }

    &--new-board-active {
        #{$save-popup}__new-board-link {
            transform: translateY(100%);
            transition: transform .6s cubic-bezier(.2, 0, 0, 1), background-color $transition-default-time $transition-default-smoothing;
            box-shadow: none;
        }

        #{$save-popup}__new-board-section {
            box-shadow: 0 rem-calc(-4) rem-calc(11) 0 rgba(map-deep-get($colors, "black", "default"), .5);
            transition: all .6s cubic-bezier(.2, 0, 0, 1) .3s;
            transform: translateY(0);
        }
    }

    &__variants {
        width: 100%;
        border: none;
        padding: 0;
        margin: 0;
    }

    &__variant {
        display: table-row;

        svg {
            margin-right: 0;
        }

        /* stylelint-disable */
        &-icon,
        &-image,
        &-content,
        &-price {
            padding-right: rem-calc(5);
        }

        &-icon {
            width: rem-calc(18);
        }

        &-image {
            width: rem-calc(75);

            img {
                max-height: rem-calc(75);
            }
        }

        &-link {
            a {
                color: map-deep-get($colors, "green", "default");
                text-decoration: underline;
            }
        }

        &-title {
            font-weight: 700;
        }
        /* stylelint-enable */
    }
}
