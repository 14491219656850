.floorplan-duplicate {
    &__controls {
        position: absolute;
        top: 0;
        right: 24px;
        bottom: 0;
        left: 24px;
        background-color: map-deep-get($colors, "green", "default");
        z-index: 5;
        color: map-deep-get($colors, "white", "default");
        padding: 0 24px;

        &--height {
            height: 100%;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
    }

    &__pointer {
        z-index: 5;
    }
}
