.message {
    position: fixed;
    bottom: rem-calc(24);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transform: translateY(30px);
    margin-left: rem-calc(9999);
    transition: margin-left 0s linear .4s, transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1);
    z-index: 13;
    $message: &;

    &__title {
        font-weight: 800;
        font-size: rem-calc(18);
        display: block;
        margin: 0;
        padding: 0 0 8px;
    }

    &__text {
        padding: 24px 48px;
        background-color: rgba(map-deep-get($colors, "green", "default"), .9);
        display: inline-block;
        border-radius: rem-calc(50);
        color: map-deep-get($colors, "white", "default");
    }

    &--error {
        #{$message}__text {
            background-color: rgba(map-deep-get($colors, "validation", "invalid-dark"), .9);
            padding: 12px;
            border-radius: 6px;

            @include breakpoint(large) {
                padding: 24px;
                max-width: 33.333vw;
                min-width: 25vw;
            }
        }

        #{$message}--active {
            top: rem-calc(24);
            right: rem-calc(24);
            bottom: auto;
            left: auto;
        }
    }

    &--active {
        opacity: 1;
        transform: translateY(0);
        margin-left: 0;
        transition: margin-left 0s linear, transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1);
    }

    &__close {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
        line-height: 30px;
        text-align: center;
        font-size: 2em;
        color: map-deep-get($colors, "white", "default");
        font-family: monospace;

        &:hover {
            color: rgba(map-deep-get($colors, "white", "default"), .5);
        }
    }

    &__lines {
        display: block;
        padding: 0;
        margin: 0;
    }

    &__line {
        display: block;
        padding: 0 8px;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}