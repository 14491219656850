.product-config-finished {
    border: 1px solid map-deep-get($colors, "gray", "dark");
    padding: 30px 30px 0;
    margin-bottom: 30px;

    &__title-section {
        text-align: center;
        margin: 0 auto rem-calc(25);

        @include breakpoint(large) {
            width: 75%;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem-calc(30);
    }

    &__button {
        margin: 0 rem-calc(20);
    }

    &__projects {
        text-align: center;
        margin-bottom: rem-calc(20);
    }
}
