.edit-floorplans-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 12;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
    margin-left: rem-calc(9999);
    opacity: 0;
    transition: opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear .4s;
    $add-pointer-popup: &;

    &__bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
    }

    &__content {
        position: relative;
        z-index: 2;
        max-width: rem-calc(1024);
        width: 100%;
        transition: all .4s cubic-bezier(.2, 0, 0, 1);
        transform: translateY(30px);
        background-color: map-deep-get($colors, "white", "default");
        padding: 32px;
        border-radius: $border-radius-default;
        opacity: 0;
        max-height: 80vh;
        overflow: auto;

        &--active {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &--active {
        opacity: 1;
        margin-left: 0;
        transform: translateY(0);
        transition: transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1), margin 0s linear;
    }
}
