@keyframes blink {
    50% {
        color: transparent;
    }
}

.loading-blink__dot {
    animation: 1s blink infinite;

    &:nth-child(2) {
        animation-delay: 250ms;
    }

    &:nth-child(3) {
        animation-delay: 500ms;
    }
}
