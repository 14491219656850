.card-list {
    margin: rem-calc(56) 0;

    @include breakpoint(medium) {
        margin: rem-calc(100) 0;
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }

    &__read-more {
        display: flex;
        align-items: center;
        color: map-deep-get($colors, "blue", "default");
        cursor: pointer;
        font-weight: 700;
        text-decoration: underline;
        margin-top: rem-calc(16);

        @include breakpoint(medium) {
            margin-top: 0;
        }

        svg {
            width: rem-calc(20);
            fill: map-deep-get($colors, "blue", "default");
        }
    }
}
