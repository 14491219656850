.product-config-summary {
    position: relative;
    overflow: hidden;
    top: 0;
    border: 1px solid map-deep-get($colors, "gray", "default");
    border-radius: $border-radius-default;
    max-width: rem-calc(500);
    text-align: center;
    margin: rem-calc(20) auto;
    $product-config-summary: &;

    @include breakpoint(large) {
        &:not(#{$product-config-summary}--nonsticky) {
            margin: 0 auto;
            max-height: 98vh;
            position: sticky;
            top: 0;
        }
    }

    &__scroll-wrapper {
        @include breakpoint(large) {
            overflow-y: auto;
            max-height: 90vh;
        }
    }

    &__title-wrapper {
        padding: 20px 15px;
    }

    &__item-left-content {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 15px;
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
    }

    &__item-price {
        margin-left: rem-calc(20);
    }

    &__image {
        width: rem-calc(40);
        margin-right: rem-calc(10);
    }

    &__num {
        width: rem-calc(45);
        height: rem-calc(45);
        border-radius: rem-calc(4);
        margin-left: rem-calc(10);
        padding: 0 5px;
        text-align: center;
    }

    &__remove {
        margin-left: rem-calc(15);
        line-height: 1;

        svg {
            width: rem-calc(24);
            fill: map-deep-get($colors, "validation", "invalid");
        }
    }

    &__notice {
        background-color: map-deep-get($colors, "green", "lightest");
        margin: rem-calc(15);
        padding: 20px;

        &--invalid {
            background-color: map-deep-get($colors, "validation", "invalid");
            color: map-deep-get($colors, "white", "default");

            #{$product-config-summary}__notice-num {
                color: map-deep-get($colors, "white", "default");
            }

            #{$product-config-summary}__notice-title {
                color: map-deep-get($colors, "white", "default");
            }
        }
    }

    &__notice-num {
        color: map-deep-get($colors, "green", "default");
    }

    &__notice-title {
        color: map-deep-get($colors, "green", "default");
        font-weight: 600;
    }

    &__notice-text {
        margin: rem-calc(5) 0 0;
    }

    &__button-wrapper {
        margin-top: rem-calc(40);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 25px 25px;
    }

    &__info-text {
        font-size: rem-calc(12);
        font-weight: 300;
        width: 100%;
        margin-bottom: 0;
    }

    &__total {
        margin: rem-calc(30) rem-calc(15) rem-calc(15);
    }

    &__total-price-val {
        font-size: rem-calc(24);
        color: map-deep-get($colors, "green", "default");
        font-weight: 500;
    }

    &__sub-text {
        border-top: 1px solid map-deep-get($colors, "gray", "dark");
        padding: 15px;
        font-size: rem-calc(14);
    }

    &--green {
        border: none;
        background-color: map-deep-get($colors, "green", "lightest");
        box-shadow: $box-shadow-default;

        #{$product-config-summary}__item {
            border-bottom: none;
        }
    }
}
