.boards-overview {
    margin: rem-calc(24) 0;

    @include breakpoint(medium) {
        margin: rem-calc(48) 0;
    }

    &--basic {
        @include breakpoint(large) {
            margin: rem-calc(30) 0 rem-calc(100) 0;
        }
    }

    &--push-bottom {
        margin-bottom: rem-calc(96);
    }

    &__title {
        margin-bottom: rem-calc(40);
        font-size: rem-calc(18);
        display: block;
    }

    &__link {
        display: inline-block;
        color: map-deep-get($colors, "blue", "default");
        text-decoration: underline;

        @include breakpoint(medium) {
            margin-left: rem-calc(20);
        }

        svg {
            display: inline-block;
            width: rem-calc(24);
            max-height: rem-calc(18);
            fill: map-deep-get($colors, "blue", "default");
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}
