.file {
    position: relative;
    padding: 20px;
    border: 2px dashed rgba(0, 0, 0, .3);
    text-align: center;

    &__label {
        color: rgba(0, 0, 0, .3);
        font-size: 20px;
    }

    &__label.has-error {
        color: map-deep-get($colors, "validation", "invalid");
    }

    &__input {
        position: absolute;
        transition: none;
        cursor: pointer;
        left: 0;
        top: 0;
        opacity: 0;
        max-height: 100%;
        max-width: 100%;
    }

    &--dragging {
        border: 2px dashed rgba(0, 0, 0, .5);
        color: rgba(0, 0, 0, .5);
    }

    &__click {
        position: relative;
        cursor: pointer;
        display: inline-block;
        width: auto;
        margin-top: 20px;
        padding: 4px 12px;
        min-height: rem-calc(20);
        border-radius: 15px;
        font-size: 12px;
    }
}
