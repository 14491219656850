.dropdown {
    display: inline-block;
    position: relative;
    z-index: 5;
    max-width: rem-calc(300);

    &__select {
        border-radius: 20px;
        border: 1px solid map-deep-get($colors, "gray", "dark");
        background-color: map-deep-get($colors, "white", "default");
        height: rem-calc(40);
        line-height: rem-calc(40);
        width: 100%;
        padding: 0 24px;
        min-width: rem-calc(230);
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: $transition-default;

        svg {
            position: absolute;
            right: rem-calc(24);
            width: rem-calc(25);
            height: auto;
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "green", "lightest");
            border-color: map-deep-get($colors, "green", "lightest");
        }
    }

    &__select-value {
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 30px;
    }

    &__results-wrapper {
        overflow: hidden;
        margin-top: rem-calc(8);
        background-color: map-deep-get($colors, "green", "lightest");
        border-radius: 20px;
        padding: 24px 0;
        width: 100%;
        box-shadow: $box-shadow-around;
        transform: translateY(20px);
        opacity: 0;
        display: none;
        margin-left: 9999px;
        transition: opacity .5s cubic-bezier(.2, 0, 0, 1), margin 0s linear .3s, transform .5s cubic-bezier(.2, 0, 0, 1);
        position: absolute;
        top: 100%;

        &--active {
            display: block;
            margin-left: 0;
            transform: translateY(0);
            opacity: 1;
            transition: opacity .5s cubic-bezier(.2, 0, 0, 1), margin 0s linear, transform .5s cubic-bezier(.2, 0, 0, 1);
        }
    }

    &__results {
        max-height: rem-calc(200);
        //margin-right: rem-calc(-18);
        overflow: auto;

        &::before {
            content: "";
            border-color: transparent transparent map-deep-get($colors, "green", "lightest") transparent;
            border-width: 7px;
            border-style: solid;
            position: absolute;
            right: rem-calc(30);
            top: rem-calc(-14);
            width: 0;
            height: 0;
        }
    }

    &__results-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 8px 24px;
        transition: $transition-default;
        line-height: 1.4;

        svg {
            width: rem-calc(18);
            height: rem-calc(18);
            fill: map-deep-get($colors, "green", "default");
            margin-right: rem-calc(8);
            opacity: 0;
            flex-shrink: 0;
        }

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "green", "lighter");
        }

        &--active {
            svg {
                opacity: 1;
            }
        }

        &--disabled {
            cursor: auto;
        }
    }

    &__results-placeholder {
        color: rgba(map-deep-get($colors, "black", "default"), .5);
        padding: 0 24px;
        margin-bottom: rem-calc(8);
        display: block;
    }

    &__results-link {
        padding: 0 24px;
        margin-top: rem-calc(8);
        color: map-deep-get($colors, "green", "default");
        text-decoration: underline;
        display: block;
    }
}
