.discussion {
    $discussion: &;

    &__bg {
        position: fixed;
        right: 0;
        left: 100%;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "black", "default"), .8);
        z-index: 12;
        opacity: 0;
        transition: opacity .4s cubic-bezier(.2, 0, 0, 1), left 0s linear .4s;
    }

    &__window {
        overflow: hidden;
        background-color: map-deep-get($colors, "green", "lightest");

        @include breakpoint(large) {
            padding-right: 300px;
        }
    }

    &__list {
        padding: 24px;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        min-height: rem-calc(345);
        //margin: 0 rem-calc(-8) rem-calc(64) 0;

        @include breakpoint(medium) {
            height: rem-calc(500);
        }
    }

    &__list-item {
        display: flex;

        &:not(:first-of-type) {
            margin-top: rem-calc(16);
        }

        &--me {
            justify-content: flex-end;
        }
    }

    &__comment {
        background-color: map-deep-get($colors, "white", "default");
        border-radius: 10px;
        box-shadow: $box-shadow-around;
        max-width: 80%;
        width: 50%;
        min-width: rem-calc(250);
        padding: 12px;
        position: relative;

        &::before {
            content: "";
            border-color: map-deep-get($colors, "white", "default") transparent transparent transparent;
            border-width: 10px;
            border-style: solid;
            position: absolute;
            left: rem-calc(-10);
            top: 0;
            width: 0;
            height: 0;
        }

        &--me {
            background-color: map-deep-get($colors, "green", "lighter");

            &::before {
                content: "";
                right: rem-calc(-10);
                left: unset;
                border-color: map-deep-get($colors, "green", "lighter") transparent transparent transparent;
            }
        }

        &--new {
            background-color: map-deep-get($colors, "green", "default");

            &::before {
                border-color: map-deep-get($colors, "green", "default") transparent transparent transparent;
            }
        }
    }

    &__comment-author {
        color: map-deep-get($colors, "blue", "default");
        font-weight: 700;
        position: relative;
        z-index: 1;
    }

    &__comment-content {
        margin: rem-calc(8) 0 0;
        white-space: pre-wrap;
    }

    &__comment-inner-wrapper {
        overflow: hidden;
    }

    &__comment-date {
        color: map-deep-get($colors, "gray", "darker");
        float: right;
        font-size: rem-calc(12);
        text-transform: uppercase;
    }

    &__place-comment {
        display: flex;
        z-index: 2;
        padding: 24px;
    }

    &__place-comment-input {
        resize: none;
        border-radius: rem-calc(20);
        min-height: rem-calc(200);
        padding: 12px;
        margin-right: rem-calc(8);
    }

    &__place-comment-button {
        width: rem-calc(44);
        height: rem-calc(44);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: map-deep-get($colors, "blue", "default");
        padding-left: 3px;
        flex-shrink: 0;
        transition: $transition-default;

        &:hover,
        &:focus {
            background-color: map-deep-get($colors, "green", "default");
        }

        svg {
            fill: map-deep-get($colors, "white", "default");
        }
    }

    &--active {
        #{$discussion}__window {
            opacity: 1;
            transform: translateY(0);
            right: rem-calc(12);
            transition: transform .4s cubic-bezier(.2, 0, 0, 1), opacity .4s cubic-bezier(.2, 0, 0, 1), right 0s linear;

            @include breakpoint(medium) {
                right: rem-calc(24);
            }
        }

        #{$discussion}__bg {
            left: 0;
            opacity: 1;
            transition: opacity .4s cubic-bezier(.2, 0, 0, 1), left 0s linear;
        }
    }

    &__guest {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 11;
        background-color: rgba(map-deep-get($colors, "green", "lighter"), 0.8);

        /* stylelint-disable */
        &-wrapper {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            background-color: map-deep-get($colors, "white", "default");
            padding: rem-calc(20);
            transform: translateY(-100%);
            transition: $transition-default;

            @media (min-width: 340px) {
                left: 50%;
                margin-left: rem-calc(-160);
                bottom: rem-calc(20);
                width: rem-calc(320);
                box-shadow: 0 0 5px 0 map-deep-get($colors, "gray", "default");
            }

            &--active {
                transform: translateY(0%);
            }
        }
        /* stylelint-enable */
    }
}
