.add-own-card {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius-default;
    background-color: map-deep-get($colors, "white", "default");
    border: 1px dashed map-deep-get($colors, "green", "default");
    padding: 15px;
    max-height: rem-calc(250);
    height: rem-calc(390);
    transition: $transition-default;

    @include breakpoint(medium) {
        max-height: none;
    }

    &__plus {
        font-size: rem-calc(80);
        line-height: 1;
        font-weight: 700;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        color: map-deep-get($colors, "green", "default");
    }

    &__text {
        text-align: center;
        width: 100%;
    }

    &:hover,
    &:focus {
        background-color: map-deep-get($colors, "green", "lightest");
    }
}