.shoppingcart-list {
    margin-top: rem-calc(24);
    $shoppingcart-list: &;

    &__items {
        margin-top: rem-calc(16);
    }

    &__title {
        margin-top: rem-calc(48);
    }

    &:first-of-type {
        #{$shoppingcart-list}__title {
            margin-top: 0;
        }
    }
}