.main .multiselect {
    &__placeholder {
        font-size: 15px;
        padding: 0 8px;
        margin: 0;
        color: #0a0a0a;
    }

    &__tags {
        border-radius: 0;
        border: 1px solid #ccc;
    }

    &__select {
        height: 40px;
    }

    &__spinner {
        right: 3px;
        height: 38px;
    }

    &--active {
        .multiselect__select {
            top: 8px;
        }
    }

    &__option {
        &--group,
        &--group--disabled {
            background: #77c19a !important;
            color: #fff !important;
            font-weight: bold;
        }

        &--highlight {
            background: #f3f9f5;
            color: #0a0a0a;

            &::after {
                background: #f3f9f5;
                color: #0a0a0a;
            }
        }
    }

    &__input {
        border-radius: 0;
        border-color: #ccc;
        border-style: solid;
        border-width: 0 0 1px 0;
    }
}
