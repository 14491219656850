.floorplan-ui {
    position: relative;
    width: 100%;
    overflow: hidden;

    &__content {
        border: 3px dashed map-deep-get($colors, "gray", "default");
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: rem-calc(40);
        border-radius: $border-radius-default;
        font-size: rem-calc(40);
        color: map-deep-get($colors, "gray", "default");
        cursor: pointer;
        height: rem-calc(300);
    }

    &__files-caption {
        margin-bottom: rem-calc(16);
    }

    &__files {
        margin-top: rem-calc(40);
    }

    &__files-list {
        border: 1px solid map-deep-get($colors, "gray", "default");
        border-radius: $border-radius-default;
    }

    &__file {
        padding: 0 12px;
        line-height: 1.2;
        width: 100%;
        font-size: rem-calc(16);
        display: flex;
        align-items: center;
        border-bottom: 1px solid map-deep-get($colors, "gray", "default");
        position: relative;

        &:last-of-type {
            border-bottom: none;
        }
    }

    &__file-position-controls {
        padding: 8px 12px 8px 0;
        border-right: 1px solid map-deep-get($colors, "gray", "default");
        min-width: rem-calc(40);
        min-height: rem-calc(40);
    }

    &__add-floorplan {
        color: map-deep-get($colors, "green", "default");
        display: inline-flex;
        align-items: center;
        margin: rem-calc(16) 0 0 rem-calc(16);
        cursor: pointer;

        svg {
            margin-right: rem-calc(8);
            width: rem-calc(24);
            fill: map-deep-get($colors, "green", "default");
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__title-input {
        margin-right: rem-calc(80);
        padding: 12px;
        height: auto;
    }

    &__title-ui {
        display: inline-block;
        padding: 0 12px;
    }

    &__up, &__down {
        display: block;
        text-align: center;
    }

    &__file-controls {
        position: absolute;
        right: rem-calc(12);
        display: inline-flex;
        align-items: center;

        svg {
            width: rem-calc(20);
            fill: map-deep-get($colors, "validation", "invalid");
        }
    }

    &__file-edit {
        cursor: pointer;
        margin-right: rem-calc(8);

        svg {
            width: rem-calc(15);
        }
    }

    &__uploading {
        display: inline-block;
        margin-left: rem-calc(12);
    }

    &__confirm-wrapper {
        float: right;
        margin-top: rem-calc(24);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__input {
        position: absolute;
        left: rem-calc(-9999);
    }

    &__confirm {
        margin-left: rem-calc(16);
    }
}
