.link-filters {
    &__filter {
        display: inline-block;
        height: rem-calc(40);
        line-height: rem-calc(40);
        border-radius: 30px;
        background-color: map-deep-get($colors, "white", "default");
        border: 1px solid map-deep-get($colors, "blue", "default");
        font-weight: 700;
        color: map-deep-get($colors, "blue", "default");
        padding: 0 24px 0 40px;
        transition: $transition-default;
        position: relative;
        margin: 0 rem-calc(16) rem-calc(16) 0;

        &::before {
            content: "";
            width: rem-calc(12);
            height: rem-calc(12);
            border-radius: 50%;
            background-color: map-deep-get($colors, "gray", "dark");
            display: inline-block;
            position: absolute;
            left: 16px;
            top: 50%;
            margin-top: rem-calc(-6);
            transition: $transition-default;
        }

        &:hover {
            &::before {
                background-color: map-deep-get($colors, "black", "default");
            }
        }

        &--active {
            background-color: map-deep-get($colors, "blue", "default");
            border-color: map-deep-get($colors, "blue", "default");
            color: map-deep-get($colors, "white", "default");

            &::before {
                background-color: map-deep-get($colors, "orange", "default");
            }

            &:hover {
                background-color: map-deep-get($colors, "black", "default");
                border-color: map-deep-get($colors, "black", "default");

                &::before {
                    background-color: map-deep-get($colors, "orange", "default");
                }
            }
        }
    }
}
