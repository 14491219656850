.related-products {
    &__title {
        margin: rem-calc(60) 0 rem-calc(20);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 rem-calc(-20);

        @include breakpoint(medium) {
            flex-wrap: nowrap;
        }
    }

    &__product {
        margin: 0 rem-calc(20);
        width: 100%;

        @include breakpoint(medium) {
            width: 33%;
        }
    }
}