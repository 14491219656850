.profile-image {
    display: inline-block;

    &__image-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        cursor: pointer;
    }

    &__img {
        width: rem-calc(100);
        border-radius: 50%;
    }

    &__icon {
        position: absolute;
        right: 0;
        bottom: 0;
        width: rem-calc(32);
        height: rem-calc(32);
        border-radius: 50%;
        background-color: map-deep-get($colors, "white", "default");
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid map-deep-get($colors, "blue", "default");

        svg {
            width: rem-calc(18);
        }
    }
}
