.floorplan-controls {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 rem-calc(-24);

    &__left-section,
    &__right-section {
        display: flex;
        padding: 0 24px;
        margin-bottom: rem-calc(16);
    }

    &__left-section {
        flex-wrap: wrap;
        width: 100%;

        @include breakpoint(medium) {
            width: auto;
        }
    }

    &__right-section {
        justify-content: flex-end;
        align-items: flex-end;
    }

    &__title {
        margin: 0 rem-calc(16) rem-calc(16) 0;

        @include breakpoint(medium) {
            margin-bottom: 0;
        }
    }
}