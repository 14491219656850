.knowledge-overview {
    margin: rem-calc(40) 0;
    position: relative;

    @include breakpoint(medium) {
        margin: rem-calc(80) 0;
    }

    &__title-wrapper {
        display: flex;
        justify-content: flex-start;
        margin-bottom: rem-calc(48);
        align-items: center;
        flex-wrap: wrap;
    }

    &__reset-search {
        @include breakpoint(medium) {
            margin-left: rem-calc(16);
        }
    }

    &__filter {
        margin-bottom: rem-calc(32);
        width: 100%;

        @include breakpoint(medium) {
            width: auto;
        }
    }

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(map-deep-get($colors, "white", "default"), .5);
        z-index: 5;
        transform: translateX(100%);
        opacity: 0;
        transition: opacity .3s ease-in-out, transform 0s linear .4s;
    }

    &--loading {
        &::before {
            opacity: 1;
            transform: translateX(0);
            transition: opacity .3s ease-in-out, transform 0s linear;
        }
    }
}