.register-form {
    &__slider {
        margin: 0 rem-calc(-32);
    }

    &__slide {
        width: 100%;
        padding: 0 32px;
        transition: $transition-default;
    }

    &__text {
        margin-bottom: rem-calc(32);
    }
}