.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0;
}

.page-link {
    position: relative;
    display: block;
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -$pagination-border-width;
    line-height: $pagination-line-height;
    color: $pagination-color;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;
    outline: 0;

    &:hover {
        z-index: 2;
        color: $pagination-hover-color;
        text-decoration: none;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
    }

    &:focus {
        z-index: 2;
        outline: $pagination-focus-outline;
        //box-shadow: $pagination-focus-box-shadow;
    }
}

.page-item {
    &:first-child {
        .page-link {
            margin-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &:last-child {
        .page-link {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    &.active .page-link {
        z-index: 1;
        color: $pagination-active-color;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border-color;
    }

    &.disabled .page-link {
        color: $pagination-disabled-color;
        pointer-events: none;
        // Opinionated: remove the "hand" cursor set previously for .page-link
        cursor: auto;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
